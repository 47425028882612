<template>
  <div class="navigation">

    <div class="top-nav-box" >
      <button class="btns quickmenu">퀵메뉴</button>
      <div class="menu-box" @click="QRRouter">
        <div class="img-box"><img src="@/assets/images/layout_icons/layout_QR.png" alt=""></div>
        <div class="text-box">근태 인증</div>
      </div>
      <div class="menu-box" @click="noEvent">
        <div class="img-box"><img src="@/assets/images/layout_icons/layout_search.png" alt=""></div>
        <div class="text-box">문서검색</div>
      </div>
      <div class="menu-box" @click="noEvent">
        <div class="img-box"><img src="@/assets/images/layout_icons/layout_QA.png" alt=""></div>
        <div class="text-box">시스템 문의</div>
      </div>
        <!-- <div class="top-nav-btn-wrap" v-if="fieldType == 'FIELD'" @click="QRRouter"> -->
          <!-- <button class="top-nav-btn" type="button"><img  id="logo-img" src = "../../assets/images/layout_icons/icon_qrbarcode.png"> QR모드 전환</button> -->
        <!-- </div> -->
        <!-- <div class="top-nav-btn-wrap" v-if="fieldType == 'FIELD'">
          <button class="top-nav-btn" type="button"><img id="logo-img" src = "../../assets/images/layout_icons/icon_request.png"> 현장 건의사항</button>
        </div> -->
        <!-- <div class="top-nav-btn-wrap" v-if="fieldType == 'FIELD'"> -->
          <!--  -->
          <!-- <button class="top-nav-btn" type="button"><img id="logo-img" src = "../../assets/images/layout_icons/icon_question.png"> 시스템 문의하기</button> -->
        <!-- </div> -->

      <!-- <form method="post" action="/" id="search-form">
        <span class="k-icon k-i-zoom"></span>
        <input type="text" name="userName" placeholder=" 검색">
      </form> -->

    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      fieldType: this.$store.state.loginMember.field.type
    }
  },
  methods: {
    QRRouter() {
      this.$router.push({name:'QRTest'})
    },
    inquiryRouter() {
      this.$router.push({name:'ca_inquiry_List'})
    },
    SearchEvent() {
      this.$router.push({name:'ca_koshaTotalSearch'})
    },
    noEvent() {
      // this.$router.push({name:'TESTPAGE'})
      alert("현재 기능 준비중 입니다.")
      return;
    }
  }
};
</script>

<style scoped lang="scss">
/*
*{
  padding: 0;
  margin: 0;
  border: none;
}*/

.navigation {
  position: relative;
  display: flex;
  flex-direction: row;
  text-align: left;
  width: 100%;
  height: 64px;
  background-color: white;
}

.top-nav-box{
  position: relative;

  display: flex;
  flex-basis: auto;
  padding-top: 10px;

  width:  1620px;
  height: 64px;
  background-color: white;
  justify-content: right;
  text-align: center;

}

.k-i-zoom {
  position: absolute;
  margin-top: 22px;
  z-index: 100;
  right: 285px;
}

.top-nav-btn-wrap{
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;

  width : 180px;
}



.top-nav-btn {
  position: relative;
  display: block;
  border: 0;
  padding: 0;
  margin:  auto 0  auto 0;
  border-radius: 0;
  font-family:'NotoSansKR';
  text-decoration: none;
  font-weight:bolder;
  font-size: 15px;
  color: #094E98 ;
  transition: 0.25s;
  opacity: 1;
  background: #FFFFFF;
  white-space: nowrap;
  text-align: left;
}



#search-form > input{
  position: relative;
  display: block;
  border: 0;
  padding: 0;
  padding-left: 15px;
  margin:  15px 10px  auto 15px;
  width: 300px;
  height: 32px;

  box-sizing: border-box;
  border: 1px solid #DDDDDD;
  border-radius: 16px;
  box-shadow: 0px 1px 6px #11111112;
  background: #FFFFFF 0% 0% no-repeat padding-box;

  white-space: nowrap;

  align-items: center;
  justify-content: center;

  outline: none;

  text-indent:20px;
}


.quickmenu {
  cursor: default;
  height: 50px;
  background-color: #094E98;
  color: white;
  border: 1px solid #094E98;
}
.menu-box {
  cursor: pointer;
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #C5C5C6;
  border-radius: 5px;
  margin-left: 10px;

  .img-box {
    background-color: #EEEEEE;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }
  .text-box {
    font-size: 15px;
    font-family: 'NotoSansKR_Bold';
    color: #094E98;
    width: 80px;
  }
}

@media(min-width: 1921px) {
  .top-nav-box {
    width: calc(100% - 57px);
    min-width: 87vw;
    max-width: 92vw;
  }
}

</style>
