<template>
<div id="wrap">
  <main id="container-horizontal">
    <div id="left-nav-wrap">
      <side-bar />
    </div>
    <div id="container-vertical">


      <div id="header">
        <navigation-bar />
      </div>

      <div id="contents-wrap">
        <app-main />
      </div>



    </div>
  </main>
</div>
</template>

<script>
// #region components
import AppMain from './components/CA_ViewMain.vue';
import SideBar from './components/CA_ViewSideBar.vue';
import NavigationBar from './components/CA_ViewNaviBar.vue';
// #endregion

export default {
  components: {
    AppMain,
    SideBar,
    NavigationBar,
  },

};
</script>

<style scoped>
*{
  margin: 0;
  padding: 0;
}

html, body { height: 100%; }
main{
  display: flex;
}

#header{
  flex: 0 0 auto;
  /* background-color:green; */
  width:  100%;
}

#wrap{
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  z-index: 1;
}


#container-horizontal{
  position: relative ;
  display: flex;
  flex-direction: row;
  width:  100%;
  height: 100%;

  overflow: hidden;
}

#container-vertical{
  position: relative ;
  display: flex;
  flex-direction: column;
  width:  100%;
  height: 100%;

  overflow: hidden;
}


#left-nav-wrap{
  position: relative;
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  width: 250px;
  height: 100%;
}
#contents-wrap{
  position: relative;
  display: flex;
  flex-direction: column;
  flex-basis: auto;
  width: 100%;
  height: 100%;

  background: transparent linear-gradient(180deg, #FFFFFF 0px, #FFFFFF 270px, #DFDFDF 270px, #DFDFDF 100%) 0% 0% no-repeat padding-box;
  background-attachment: fixed;
}



@media(min-width: 1921px) {

  #container-vertical {
    overflow-y: auto;
  }

}


</style>
