import request from '../index'

const orderApiBaseUrl = '/orders'
const paymentApiBaseUrl = '/payment'

export function orderListRequest(tenantSubgroupId) {
  return request({
    url: `${orderApiBaseUrl}`,
    method: 'get',
    params: {
        tenantSubgroupId : tenantSubgroupId
    }
  })
}

export function orderGetRequest(orderId) {
  return request({
    url: `${orderApiBaseUrl}/${orderId}`,
    method: 'get',
  })
}

export function orderAddRequest(tenantSubgroupId, customerName) {
  return request({
    url: `${orderApiBaseUrl}`,
    method: 'post',
    data :{
      tenantSubgroupId: tenantSubgroupId,
      useMonths:1,
      customerName:customerName
    }
  })
}

export function paymentApproveRequest(paymentData) {
  const { paymentKey, orderId, amount} = paymentData
  return request({
    url: `${paymentApiBaseUrl}/approve-payment`,
    method: 'post',
    data: {
      paymentKey : paymentKey,
      orderId:orderId,
      amount:amount,
      // causeTossPaymentError:true

    }
  })
}

