import axios from 'axios'

import { encrypt } from '@/utils/encrypto-util'
let timestamp 
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 30000 // request timeout
})


function getCookie(name) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(name + '=')) {
      return decodeURIComponent(cookie.substring(name.length + 1));
    }
  }
  return null;
}
// request interceptor
service.interceptors.request.use(
  config => {
    const encrpytRequireEndPoints = [
      {url: '/members/login', method: 'post'},
      {url: '/members', method: 'post'},
      {url: '/fields/*/terminate', method: 'post'},
      {url: '/members/*', method: 'put'},
      {url: '/members/leave-self', method: 'delete'}
    ]
    const needsEncryption = encrpytRequireEndPoints.some(endpoint => {
      const urlMatch = new RegExp(`^${endpoint.url.replace('*', '.*')}$`).test(config.url);
      return urlMatch && endpoint.method.toLowerCase() === config.method.toLowerCase();
    });
    
    if (needsEncryption) {
      const requestData = config.data;
      const encrpyData = encrypt(requestData);

      config.data = encrpyData;
      config.headers['Content-Type'] = 'text/plain';
    }

    //[PATCH] http://localhost:8080/document-folders/1/documents/2/content
    // https://localhost:8080/documents/1
    
    // do something before request is sent
    // console.log('before send request config: ',config)

    const whiteList = ['/members/login']
    const targetApiUrl = config.url
    // config.url = 'rewerwer' //http://localhost:8080/trewwer
    // console.log('taretApiUrl: ',targetApiUrl)

    const find = whiteList.find(item => item === targetApiUrl)
    if(!find) {
      const accessToken = getCookie('access_token');

      // const accessToken = localStorage.getItem('access-token')
      // if(targetApiUrl === '/members' && config.method === 'get') {
      //   if(accessToken) {
      //     config.headers.Authorization = `Bearer ${accessToken}`
      //   }
      if(accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`

      }
      } 
       
      
      // config.headers.Authorization = 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1c2VyMSIsImlhdCI6MTY5NzYxNTUwMSwiZXhwIjoxNjk3NzAxOTAxfQ.b1b1UhmR3ho3UrIekNWjhl81Bea_XBQRK0HO_ff_kkk'
    
    // if(!whiteList.find(item => item === taretApiUrl)) { //Authrization Header �ʿ�
    //   config.headers.Authorization = 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJhZG1pbiIsImlhdCI6MTY5NzUzNTk0MSwiZXhwIjoxNjk3NjIyMzQxfQ.-qwOqHeY_jd6Wv4wwJLn5PJq2lIj1AlnNPuAwnX7vlo'
    // }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const responseData = response.data
    timestamp = responseData.timestamp
    overrideDate()
    return responseData
  },
  error => {
    const errorResponse = error.response.data
    timestamp = errorResponse.timestamp
    overrideDate()
    return Promise.reject(errorResponse)
  }
)


export function overrideDate() {
  const OriginalDate = Date
  class MockDate extends OriginalDate {
    constructor(...args) {
      if (args.length === 0) {
        super(timestamp);
      } else {
        super(...args);
      }
    }
    static parse(dateString) {
      return OriginalDate.parse(dateString);
    }
  
    static UTC(year, month, day, hour, minute, second, millisecond) {
      return OriginalDate.UTC(year, month, day, hour, minute, second, millisecond);
    }
  }
  global.Date = MockDate;

}


export default service
