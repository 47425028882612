import request from '../index'
// import CryptoJS from 'crypto-js';

const memberApiBaseUrl = '/members'

/**
 * 로그인 요청
 * @param {*} loginId 로그인 아이디
 * @param {*} loginPassword 로그인 비밀번호
 * @returns
 */
// export function loginRequest(loginId, loginPassword) {
//   return request({
//     url: `${memberApiBaseUrl}/login`,
//     method: 'post',
//     data: {
//       id: loginId,
//       password: loginPassword
//     }
//   })
// }
export function loginRequest(loginId, loginPassword , domain) {
  // const subdomain = window.location.hostname.split('.')[0];
  return request({
    url: `${memberApiBaseUrl}/login`,
    method: 'post',
    data: {
      id: loginId,
      password: loginPassword
    },
  //  headers: {
  //     'X-Tenant-ID': 'test26'
  //  }
  })
}
/**
 * 로그인 된 회원 이름 가져오기
 * @returns
 */
export function getLoginedMemberRequest() {
  return request({
    url: `${memberApiBaseUrl}/logined`,
    method: 'get'
  })
}

/**
 * 로그아웃 요청
 * @returns
 */
export function logoutRequest() {
  return request({
    url: `${memberApiBaseUrl}/logout`,
    method: 'post'
  })
}

export function fetchMembersBySearchConditionRequest(searchCondition, pagination, isFetchHeadMembers) {
  const {
    memberName,
    memberLoginId,
    memberPositionName,
    createdStartDate,
    createdEndDate
  } = searchCondition

  return request({
    url: `${memberApiBaseUrl}`,
    method: 'get',
    params: {
      page:pagination.pageNum,
      size:pagination.pageSize,

      isFetchHeadMembers : isFetchHeadMembers,

      fieldName: searchCondition.fieldName ? searchCondition.fieldName:null,
      memberName,
      memberLoginId,
      memberPositionName,
      createdStartDate,
      createdEndDate
    }
  })
}

export function fetchMemberDetailRequest(targetMemberId) {
  return request({
    url: `${memberApiBaseUrl}/${targetMemberId}`,
    method: 'get'
  })
}

export function saveMemberRequest(formData) {
  return request({
    url: `${memberApiBaseUrl}`,
    method: 'post',
    data: {
      loginId: formData.loginId,
      loginPassword: formData.loginPassword,
      loginPasswordCheck: formData.loginPasswordCheck,
      name: formData.name,
      sex: formData.sex ? formData.sex.id:null, //성별
      birthday: formData.birthday,
      basicAddress: formData.basicAddress,
      detailAddress: formData.detailAddress,
      subPhone: formData.subPhone,
      phone: formData.phone,
      email: formData.email,
      memberPositionId:formData.position ? formData.position.id:null, //회원 직책 번호
      level:formData.level ? formData.level.id:null, //사용자 권한
      status:formData.status ? formData.status.id:null, //계정상태
      fieldId: formData.field ? formData.field.id:null //현장 아이디
    }
  })
}

export function updateMemberRequest(memberId, formData) {
  return request({
    url: `${memberApiBaseUrl}/${memberId}`,
    method: 'put',
    data: {
      loginId: formData.loginId,
      loginPassword: formData.loginPassword,
      loginPasswordCheck: formData.loginPasswordCheck,
      name: formData.name,
      sex: formData.sex ? formData.sex.id:null, //성별
      birthday: formData.birthday,
      basicAddress: formData.basicAddress,
      detailAddress: formData.detailAddress,
      subPhone: formData.subPhone,
      phone: formData.phone,
      email: formData.email,
      memberPositionId:formData.position ? formData.position.id:null, //회원 직책 번호
      level:formData.level ? formData.level.id:null, //사용자 권한
      status:formData.status ? formData.status.id:null //계정상태
    }
  })
}

export function deleteMemberRequest(targetMemberId) {
  return request({
    url: `${memberApiBaseUrl}/${targetMemberId}`,
    method: 'delete',
  })
}

export function leaveMemberRequest(loginId,loginPassword) {

  return request({
    url: `${memberApiBaseUrl}/leave-self`,
    method: 'delete',
    data : {
      id: loginId,
      password: loginPassword
    }
    
  })
}




// 결재 가능한 회원 조회

export function approvalMemberRequest() {
  return request({
    url:`${memberApiBaseUrl}/approval`,
    method:'get'
    
  })
}


// 회원 조회


export function MemberRequest() {
  return request({
    url:`${memberApiBaseUrl}`,
    method:'get'
  })
}
