import router from './router'
import store from './store'

const whiteList = ['/ca_login', '/ca_terms'] // no redirect whitelist

let sessionUpdateInterval = null;

let nextPath = '/'; 
// 1분(60초)마다 세션 정보를 업데이트하는 함수
function startSessionUpdateInterval() {
  sessionUpdateInterval = setInterval(async () => {
    await store.dispatch("FETCH_LOGIN_MEMBER");
  }, 300000); // 300초마다 실행
}

router.beforeEach(async (to, from, next) => {
  nextPath = to.fullPath
  // determine whether the user has logged in
  let isLogined = false; // 로그인 여부
  let isRootField = false; // 본사, 현장 여부

  // 세션 확인
  await store.dispatch("FETCH_LOGIN_MEMBER");
  await store.dispatch("fetchApprovalData")



  
  const loginMemberName = store.state.loginMember.member.name;
  const FieldType = store.state.loginMember.field.type;
  const LoginLevel = store.state.loginMember.member.level
  const LoginStatus = store.state.loginMember.member.status

  if (loginMemberName && LoginLevel != 'LEVEL1' && LoginStatus != 'INACTIVE') {
    isLogined = true;
  }
  if (FieldType == 'HEAD') {
    isRootField = true;
  }


  if (to.path === '/ca_terms') {
    next();
    return;
  }

  if (isLogined) {
    await store.dispatch("fieldEditListRequest")
    if(FieldType == 'HEAD') {
      await store.dispatch("DangerListRequestV2")
    }else if(FieldType == 'FIELD') {
      await store.dispatch("ViewsafeFieldDocumentListRequest")
    }
    // 로그인 된 회원일 경우
    if (to.path === "/ca_login") {
      // if is logged in, redirect to the home page
      if (FieldType == 'HEAD') {
        next({ path: "/ca_scene" });
      } else if (FieldType == 'FIELD') {
        next({ path: "/ca_home" });
      }
    } else {
      if(isRootField) { //본사
        if(to.path === '/ca_home') {
          next({path:'/ca_scene'})
        } else {
          next()
        }
      } else { //현장
        if(to.path === '/ca_scene' || to.path === '/ca_head') {
          next({path:'/ca_home'})
        } else if(to.path === '/ca_kosha/kosha_doclist/kosha_list2'|| to.path === '/ca_kosha/kosha_doclist/list1_board') { //문서 관련 경로일 때
            next({path: '/ca_kosha/kosha_doclist'})
        } else {
          next()
        }
      }
    }
  } else if (whiteList.indexOf(to.path) !== -1) {
    // in the free login whitelist, go directly
    document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    next();
  } else {
    document.cookie = "access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    document.cookie = "refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    next("/ca_login");
  }
})

router.onError((error) => {
  if(error.name === 'ChunkLoadError') {
    window.location.href = nextPath;
  }
  const chunkLoadError = /Loading chunk \d+ failed/;
  if (error.message.match(chunkLoadError)) {
    window.location.href = nextPath;
  }
});

startSessionUpdateInterval();
router.afterEach(() => {
})
