<template>
  <div class="app-main">
    <!-- <router-view :key="key" /> -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'CA_ViewMain',
  // computed: {
  //   key() {
  //     return this.$route.path;
  //   },
  // },
};
</script>

<style scoped>

.app-main {
  /* 50= navbar  50  */
    position: relative;
    display: flex;
    flex-basis: auto;
    width:  calc(100vw - 250px);
    height: 100%;
    overflow: auto;
    overflow-y: auto;

}

</style>
