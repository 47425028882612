import request from '../index'

const tenantApiBaseUrl = '/tenant-sub-groups'

export function tenantUseRequest() {
  return request({
    url: `${tenantApiBaseUrl}/retrieve-tenant-subgroup-of-client-user`,
    method: 'get',
  })
}
