import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { getLoginedMemberRequest } from '@/api/member/member-api.js';
import { approvalNumRequest } from '@/api/approval/approval-api.js'
import { fieldEditListRequest } from '@/api/checkMember/member-api.js'
import { unReadDocumentListRequest } from '@/api/doc/doc-api.js'
import { DangerListRequestV2 } from '@/api/doc/doc-list-api/danger-doc-api.js'
import { ViewsafeFieldDocumentListRequest } from '@/api/doc/doc-list-api/safefield-doc-api.js'
import { tenantUseRequest } from '@/api/SMS/tenant/tenant-api'
import { orderListRequest } from '@/api/SMS/order/order-api'
import dayjs from 'dayjs'
export default createStore({
  state: {
    useServiceAuth: 0,
    loginMember: {
      member: {
        memberId:null,
        name: null,
        level:null,
        adminLevel:null,
        positionName:null,
        rankName:null,
        status:null,
        hasApprovalPrivilege:null
      },
      field: {
        fieldId:null,
        fieldName:null,
        headName:null,
        type:null
      }
    },
    searchCondition:null,

    documentGuideWritableStatus:null,
    documentGuideVisibleStatus:null,
    DocumentGroup:null,
    revisionNumber:null, // 결재이력 번호
    InnerPageCheck:null, // 내부심사 번호 확인

    pageChecking:0, // 게시판 템플릿
    listChecking:0, // 목록 템플릿

    targetFolderId: null, // 문서목록 번호
    targetDocumentId: null, // 문서번호
    latestDocumentId: null, // 문서목록 저장용 문서번호

    viewDocumentName: null, // 문서이름
    approvalEnd:null, // 결재상태 / null : X 0 : 진행중 1 : 완료
    targetFolderGroup:null, // 선택한 폴더의 관리주체
    hasUpdatePrivilege:false, // 편집버튼 권한
    hasDocumentDetailReadPrivilege:false, // 문서접근 권한

    riskAssessmentType:null, // 위험성평가 종류


    docType:null,
    targetFieldId:null, // 현장번호

    LastCheckYear:null, // 스케줄표에서 사용할 마지막 날짜

    approvalId:null, // 결재 문서번호

    safetyDocType:null, // 안전보건목표 문서 타입

    isApprovalSupport:null, // 결재 가능문서 체크
    fieldcheck: null, // 안전보건 14 16 필터
    documentStatus:null, // 문서상태
    revisioncheck:null, // 제 개정 체크
    feedbackType:null, // 문서 피드백

    menuProgress:null, // 메뉴 배경색
    menuActive:null, // 메뉴 활성화
    dropLayout:null, // 내려오는기준

    hasDocumentDetailReadPrivilege:null, // 문서 읽기 권한
    printTitle:null, // 인쇄용 문서이름 저장
    printField:null, // 인쇄용 현장명
    SitesafetyDate : null, // 안전보건점검 초기 점검일
    nokoshalist:null, // kosha가아닌문서
    commute : {
      commuteId: null,
      loginId:null,
      startDate:null,
      endDate:null
    }, // 근태 정보

    writeStatus:null,

    viewMode:null, // 보기모드(링크)
    isLinkConfigActive:null, // 링크사용유무

    ReportId:null,
    Reloadcheck:false,

    schedate:null,
    activecheck:false,
    activecheck2:false,
    activecheck3:false,
    ScrollInfo:0,
    pdfURL:null,

    lengthcheck:0,
    readCheck: 0,
    finalcheck:'',

    createCheck:0,
    beforepage:0,
    usecheck:0,
  
    boardcheck:0,
    Addcheck:0,
    saveId:0,
 
    groupfilter: {
      head : false,
      field : false
    },
    
    documentFolderId:null,
    approvalPrefix:null,


    previousData:false,

    startDate:null,
    endDate:null,

    docGroupData:null,

    planId:null, // 스케줄수정
    recplanId:null,
    reccheck:null,
    planedit:null,

    todayinfo:null,


    episodeNumber:null, // 위험성평가 회차

    // 위험성

    searchFilter:null,
    documentstatus:null,
    refreshdata:null,

    serviceDate:null, // 본사 생성일

    approvalcheck:'',

    approvalData: [
      {
        type: null,
        totalCount: 0,
        readCount: 0,
        unreadCount: 0
      }
    ],
    unReadData:[],
    approvalstatus:null,
    riskDocumentCount:null,
    safetyDocumentCount:null

    
  },
  mutations: {
       
    sethasDocumentDetailReadPrivilege(state, newData) {
      state.hasDocumentDetailReadPrivilege = newData
    },
    setpdfURL(state,newData) {
      state.pdfURL = newData
    },
    setdocumentGuideWritableStatus(state,newData) {
      state.documentGuideWritableStatus = newData
    },
    setdocumentGuideVisibleStatus(state,newData) {
      state.documentGuideVisibleStatus = newData
    },
    setDocumentGroup(state,newData) {
      state.DocumentGroup = newData
    },
    setDocumentGroup(state,newData) {
      state.DocumentGroup = newData
    },
    settargetFolderId(state,newData) {
      state.targetFolderId = newData
    },
    setepisodeNumber(state,newData) {
      state.episodeNumber = newData
    },
    setsearchFilter(state,newData) {
      state.searchFilter = newData
    },
    setfinalcheck(state,newData) {
      state.finalcheck = newData
    },
    setfieldcheck(state,newData) {
      state.fieldcheck = newData
    },
    setrevisioncheck(state,newData) {
      state.revisioncheck = newData
    },
    sethasUpdatePrivilege(state,newData) {
      state.hasUpdatePrivilege = newData
    },
    setprintField(state,newData) {
      state.printField = newData
    },
    setfeedbackType(state, newId) {
      state.feedbackType = newId;
    },
    setwriteStatus(state, newId) {
      state.writeStatus = newId;
    },
    setpageChecking(state, newId) {
      state.pageChecking = newId;
    },
    setviewDocumentName(state, newId) {
      state.viewDocumentName = newId;
    },
    setTargetDocumentId(state, newId) {
      state.targetDocumentId = newId;
    },
    setPrintTitle(state, newId) {
      state.printTitle = newId;
    },
    setTargetFolderId(state, newId) {
      state.targetFolderId = newId;
    },
    setDocumentStatus(state, payload) {
      state.documentStatus = payload;
    },
    setPageChecking(state, payload) {
      state.pageChecking = payload;
    },
    setviewDocumentName(state, payload) {
      state.viewDocumentName = payload;
    },
    setisApprovalSupport(state, payload) {
      state.isApprovalSupport = payload;
    },

    SET_LOGIN_MEMBER(state, name) {
      state.loginMember.member.name = name;
    },
    SET_LOGIN_MEMBERID(state, memberId) {
      state.loginMember.member.memberId = memberId;
    },
    SET_LOGIN_LEVEL(state, level) {
      state.loginMember.member.level = level;
    },
    SET_LOGIN_ADMINLEVEL(state, adminLevel) {
      state.loginMember.member.adminLevel = adminLevel;
    },
    SET_LOGIN_POSITIONNAME(state, positionName) {
      state.loginMember.member.positionName = positionName;
    },
    SET_LOGIN_RANKNAME(state, rankName) {
      state.loginMember.member.rankName = rankName;
    },
    SET_LOGIN_STATUS(state, status) {
      state.loginMember.member.status = status;
    },
    SET_LOGIN_APPROVAL(state, approval) {
      state.loginMember.member.hasApprovalPrivilege = approval;
    },




    SET_LOGIN_FIELDID(state, fieldId) {
      state.loginMember.field.fieldId = fieldId;
    },
    SET_LOGIN_FIELD_NAME(state, fieldName) {
      state.loginMember.field.fieldName = fieldName;
    },
    SET_LOGIN_FIELD_HEADNAME(state, headName) {
      state.loginMember.field.headName = headName;
    },
    SET_LOGIN_FIELD_TYPE(state, fieldtype) {
      state.loginMember.field.type = fieldtype;
    },
    SET_APPROVAL_STATUS(state, status) {
      state.approvalcheck = status;
    },
    SET_APPROVAL_DATA(state, data) {
      state.approvalData = data
    },
    SET_RISKDOCUMENT_DATA(state, data) {
      state.riskDocumentCount = data
    },
    SET_SAFETYDOCUMENT_DATA(state, data) {
      state.safetyDocumentCount = data
    },
    SET_SERVICE_DATE(state, data) { // 생성일
      state.serviceDate = data
    },
    SET_useServiceAuth(state, data) {
      state.useServiceAuth = data
    }
  },
  actions: {
    async ViewsafeFieldDocumentListRequest({ commit }) {
      try {
        
        let searchCondition = {
          pagination: {
            page:0,
            size:5
          },
          documentFolderId:45,
          feedbackType:1
        }
        const res = await ViewsafeFieldDocumentListRequest(searchCondition)
        commit('SET_SAFETYDOCUMENT_DATA', res.data.totalElements)
      } catch (error) {
        console.error(error.message)
      }
    },
    
    async DangerListRequestV2({ commit }) {
      try {
        
        let searchCondition = {
          pagination: {
            page:0,
            size:5
          },
          documentFolderId:32,
          feedbackType:1
        }
        const res = await DangerListRequestV2(searchCondition)
        commit('SET_RISKDOCUMENT_DATA', res.data.totalElements)
      } catch (error) {
        console.error(error.message)
      }
    },
    async tenantUseRequest(context) {
      try {
        const res = await tenantUseRequest();
    
        if (res.data.currentSubscription) {
          if(res.data.currentSubscription.status == 'TERMINATE') {
            context.commit('SET_useServiceAuth', 1); // 최초 결제 안내
          }else {
            const currentEndDate = new Date(res.data.currentSubscription.endDate);
            currentEndDate.setHours(0, 0, 0, 0); // 시간을 00:00으로 설정하여 날짜만 비교
      
            const today = new Date();
            today.setHours(0, 0, 0, 0); // 시간을 00:00으로 설정하여 날짜만 비교
      
            // 두 날짜 차이 계산 (밀리초 단위로 빼고 일 단위로 환산)
            const timeDifference = currentEndDate - today;
            const dayDifference = timeDifference / (1000 * 60 * 60 * 24);
      
            // 만료일이 10일 이하로 남았거나 지난 경우
            if (dayDifference <= 10) {
                context.commit('SET_useServiceAuth', 2); // 만료일 10일 전부터 안내
         
            } else {
              context.commit('SET_useServiceAuth', 0); // 정상 상태
            }
          }
        } else {
          context.commit('SET_useServiceAuth', 1); // 최초 결제 안내
        }
      } catch (error) {
        alert(error.message);
      }
    },
    async fetchApprovalData({ commit }) {
      try {
        const res = await approvalNumRequest()
        commit('SET_APPROVAL_DATA', res.data)
      } catch (error) {
        console.error(error.message)
      }
    },



    async fieldEditListRequest(context) {
      try {
        if (this.state.serviceDate == null) {
          const res = await fieldEditListRequest(1);
    
          const createdDate = dayjs(res.data.createdDate);
          const currentYear = dayjs().year();
    
          // createdDate부터 현재 연도까지의 연도 리스트 생성
          const years = [];
          for (let year = createdDate.year(); year <= currentYear; year++) {
            years.push(year);
          }
    
          context.commit("SET_SERVICE_DATE", years);
        } else {
          return;
        }
      } catch (error) {
        alert(error.message);
      }
    },
    async FETCH_LOGIN_MEMBER(context) {
      try {
        // const loginMemberName = await getLoginedMemberRequest();
        const response = await getLoginedMemberRequest();
        const memberID = response.data.member.memberId;
        const memberName = response.data.member.name;
        const Level = response.data.member.level;
        const adminLevel = response.data.member.adminLevel;
        const positionName = response.data.member.positionName;
        const rankName = response.data.member.rankName;
        const status = response.data.member.status;
        const approval = response.data.member.hasApprovalPrivilege;

        const fieldID = response.data.field.fieldId;
        const fieldName = response.data.field.name;
        const headName = response.data.field.headName;
        const fieldType = response.data.field.type;
        


        // 본사: true, 현장: false

        context.commit("SET_LOGIN_MEMBER", memberName);
        context.commit("SET_LOGIN_MEMBERID", memberID);
        context.commit("SET_LOGIN_LEVEL", Level);
        context.commit("SET_LOGIN_ADMINLEVEL", adminLevel);
        context.commit("SET_LOGIN_POSITIONNAME", positionName);
        context.commit("SET_LOGIN_RANKNAME", rankName);
        context.commit("SET_LOGIN_STATUS", status);
        context.commit("SET_LOGIN_APPROVAL", approval);


        context.commit("SET_LOGIN_FIELDID", fieldID);
        context.commit("SET_LOGIN_FIELD_NAME", fieldName);
        context.commit("SET_LOGIN_FIELD_HEADNAME", headName);
        context.commit("SET_LOGIN_FIELD_TYPE", fieldType);
        
      } catch (error) {
        console.log(error);
        context.commit("SET_LOGIN_MEMBER", null);
        context.commit("SET_LOGIN_MEMBERID", null);
        context.commit("SET_LOGIN_LEVEL", null);
        context.commit("SET_LOGIN_ADMINLEVEL", null);
        context.commit("SET_LOGIN_POSITIONNAME", null);
        context.commit("SET_LOGIN_RANKNAME", null);
        context.commit("SET_LOGIN_STATUS", null);
        context.commit("SET_LOGIN_APPROVAL", null);


        context.commit("SET_LOGIN_FIELDID", null);
        context.commit("SET_LOGIN_FIELD_NAME", null);
        context.commit("SET_LOGIN_FIELD_HEADNAME", null);
        context.commit("SET_LOGIN_FIELD_TYPE", null);
      }
    },
  },
  getters: {
    // getApprovalData: state => state.approvalData,
    getMemberData: state => state.loginMember,
    // unReadRiskDocumentListRequest: state => {
      // return state.riskDocumentCount
    // },
    // unReadSafetyDocumentListRequest: state => {
    //   return state.unReadData.find(item => item.documentFolderId === 45) || { unReadDocumentsCount: 0 };
    // },
    pendingApprovalData: state => {
      return state.approvalData.find(item => item.type === 'PENDING') || { totalCount: 0, readCount: 0, unreadCount: 0 };
    },
    inProgressApprovalData: state => {
      return state.approvalData.find(item => item.type === 'IN_PROGRESS') || { totalCount: 0, readCount: 0, unreadCount: 0 };
    },
    approveApprovalData: state => {
      return state.approvalData.find(item => item.type === 'APPROVE') || { totalCount: 0, readCount: 0, unreadCount: 0 };
    },
    returnApprovalData: state => {
      return state.approvalData.find(item => item.type === 'RETURN') || { totalCount: 0, readCount: 0, unreadCount: 0 };
    }

  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
      paths: ["approvalcheck",
      'targetFolderId',
      'targetDocumentId',
      'viewDocumentName',
      'approvalEnd',
      'targetFolderGroup',
      'hasUpdatePrivilege',
      'pageChecking',
      'listChecking',
      'approvalId',
      'approvalPrefix',
      'approvalstatus',
      'safetyDocType',
      'isApprovalSupport',
      'fieldcheck',
      'targetFieldId',
      'documentStatus',
      'usecheck',
      'latestDocumentId',
      'searchFilter',
      'finalcheck',
      'feedbackType',
      'writeStatus',
      'planId',
      'menuProgress',
      'menuActive',
      'dropLayout',
      'commute',
      'SitesafetyDate',
      'viewMode',
      'isLinkConfigActive',
      'linkDocumentFolders',
      'nokoshalist',
      'lengthcheck',
      'saveId',
      'revisionNumber',
      'printTitle',
      'docType',
      'printField',
      'riskAssessmentType',
      'DocumentGroup',
      'documentGuideVisibleStatus',
      'documentGuideWritableStatus',
      'pdfURL',
      'serviceDate'
    ],
      
    })
  ]
});
