import request from '../index'

const docApiBaseUrl = 'document-folders'

const BaseDocUrl = 'base-documents'

export function documentTotalSearchRequest(searchCondition) {
  const {
    pagination,
    fieldId,
    documentFolderCandidateGroup,
    searchKeywordType,
    searchKeyword,
    startDate,
    endDate
  } = searchCondition

  return request({
    url: `${BaseDocUrl}/page-info`,
    method: 'get',
    params: {
      page:pagination.page,
      size:pagination.size,
      fieldId:fieldId,
      documentFolderCandidateGroup:documentFolderCandidateGroup,
      searchKeywordType: searchKeywordType,
      searchKeyword :searchKeyword,
      searchDurationType:'CONTENT_LAST_MODIFIED_DATE',
      searchStartDate:startDate,
      searchEndDate:endDate,
    }
  })
}

export function documentAuthSettingRequest(documentId) {
  return request({
    url: `${BaseDocUrl}/${documentId}`,
    method: 'get'
  })
}

export function docList() {
  return request({
    url: `${docApiBaseUrl}`,
    method: 'get'
  })
}

export function LinkListDocumentRequest() {
  return request({
    url: `${docApiBaseUrl}`,
    method: 'get',
  })
}

export function LinkListViewDocumentRequest(documentId) {
  return request({
    url: `${docApiBaseUrl}`,
    method: 'get',
    params :{
      documentFolderId:documentId
    }
  })
}
export function ListDocumentRequest(searchCondition) {
  const {
    group,
    keyword,
    keywordType
  } = searchCondition

  return request({
    url: `${docApiBaseUrl}`,
    method: 'get',
    params: {
      group : group,
      [keywordType] : keyword,
      
    }
  })
}

export function ListDocumentGroupRequest(documentFolderId, group) {
  return request({
    url: `${docApiBaseUrl}`,
    method: "get",
    params: {
      group: group,
      documentFolderId: documentFolderId,
    },
  });
}

export function ListDocumentGroupSaveRequest(documentId, groupSetting) {
  const { shareFlag, updatePermission, permissionLevel, documentGuideWritableStatus, documentGuideVisibleStatus } = groupSetting;

  return request({
    url: `${docApiBaseUrl}/${documentId}`,
    method: "put",
    data: {
      shareFlag: shareFlag,
      updatePermission: updatePermission,
      permissionLevel: permissionLevel,
      documentGuideWritableStatus:documentGuideWritableStatus,
      documentGuideVisibleStatus:documentGuideVisibleStatus
    },
  });
}



export function CompleteDocumentCheck(documentId) {
  return request({
    url: `${docApiBaseUrl}/${documentId}/check`,
    method: "get",
  });
}


export function DocumentDeleteRequest(documentId) {
  return request({
    url: `${BaseDocUrl}/${documentId}`,
    method: 'delete'
  })
}

export function PatchlinkListRequest(documentId,isLinkConfigActive,linkDocumentFolderIds) {
  return request({
    url: `${docApiBaseUrl}/${documentId}/link-config`,
    method: 'patch',
    data:{
      isLinkConfigActive:isLinkConfigActive,
      linkDocumentFolderIds:linkDocumentFolderIds,
    }
  })
}


// export function TotalDocumentListRequest(year, searchCondition) {
//   const { searchType,searchContent } = searchCondition
//   return request({
//     url: `${BaseDocUrl}/statistics-info`,
//     method: 'get',
//     params:{
//       year:year,
//       [searchType]:searchContent
//     }
//   })
// }

export function TotalDocumentListRequest(year, searchCondition) {
  const { isShareFixed,searchType,searchContent,documentFolderCandidateGroup,overallWriteStatus ,isSharedToSlaveField} = searchCondition
  return request({
    url: `document-folder/statistics/completion-statistics`,
    method: 'get',
    params:{
      year:year,
      [searchType]:searchContent,
      fieldId:searchCondition.fieldId,
      documentFolderCandidateGroup:documentFolderCandidateGroup,
      overallWriteStatus:overallWriteStatus,
      isSharedToSlaveField:isSharedToSlaveField,
      documentFolderIds:searchCondition.documentFolderIds,
      documentWriteCycle:searchCondition.documentWriteCycle,
      isShareFixed:isShareFixed
      // documentFolderId:documentFolderId
    }
  })
}

export function ShareDocumentListRequest() {
  return request({
    url: `document-folder/statistics/shared-document-folder`,
    method: 'get',
  })
}

export function FieldRegisterDocumentListRequest() {
  return request({
    url: `document-folder/statistics/slave-field-document-folder`,
    method: 'get',
  })
}

export function MasterFieldDocumentListRequest() {
  return request({
    url: `document-folder/statistics/master-field-inspection-activity-statistics`,
    method: 'get',
  })
}

export function safetyDocumentToFieldRequest(searchCondition) {
  const { year,fieldStatus,fieldId } = searchCondition
  return request({
    url: `document-folder/statistics/safety-health-establish-completion-statistics`,
    method: 'get',
    params: {
      year:year,
      fieldStatus:fieldStatus,
      fieldId:fieldId
    }
  })
}

export function readNewDocumentRequest(documentId) {
  return request({
    url: `${BaseDocUrl}/${documentId}/read`,
    method: 'post'
  })
}

export function unReadDocumentListRequest() {
  return request({
    url: `${BaseDocUrl}/unread-documents-info`,
    method: 'get',
    params: {
      documentFolderIds: '32,45'
    }
  })
}