import request from '../../index'

import dayjs from 'dayjs'
import dateFormatConstants from '@/common/constants/DateFormatConstants'
const DangerDocApiUrl= '/documents/risk-assessment'




export function DangerListRequest(searchCondition) {
    const {documentFolderId, fieldType,type,pagination} = searchCondition
    return request({
        url: `${DangerDocApiUrl}`,
        method:'get',
        params: {
            documentFolderId : documentFolderId,
            fieldType: fieldType,
            type: type,
            page: pagination.page,
            size: pagination.size,
        }
    })
}

export function DangerListRequestV2(searchCondition) {
    const {documentFolderId, fieldId,type,pagination,startDate,writeStatus,writer,
        endDate,feedbackType,episodeId} = searchCondition
    return request({
        url: `${DangerDocApiUrl}`,
        method:'get',
        params: {
            episodeId:episodeId,
            documentFolderId : documentFolderId,
            fieldId: fieldId,
            startDate: startDate,
            endDate: endDate,
            type: type,
            writer:writer,
            writeStatus: writeStatus,
            page: pagination.page,
            size: pagination.size,
            feedbackType:feedbackType
        }
    })
}


export function DangerListCreateRequest(documentFolderId,items,type,sDate,eDate) {
 
    return request({
        url: `${DangerDocApiUrl}`,
        method:'post',
        data: {
            documentFolderId : documentFolderId,
            type: type,
            activeSetting: {
                activeWS: items[0].ActiveStatus,
                activeRAT: items[1].ActiveStatus,
                activeRAM: items[2].ActiveStatus,
                activeRAR: items[3].ActiveStatus,
                activeRAE: items[4].ActiveStatus,
                activeRAOI: items[5].ActiveStatus,
            },
            startDate: sDate,
            endDate:eDate
        }
    })
}
// export function DangerListCreateRequest(documentFolderId,type) {
//     return request({
//         url: `${DangerDocApiUrl}`,
//         method:'post',
//         data: {
//             documentFolderId : documentFolderId,
//             type: type,

//         }
//     })
// }
export function DangerListPatchRequest(documentId,items,contents,process,sDate,eDate,conferenceType,conferenceIds,conferenceFile,attendanceType,attendanceIds,attendanceFile) {
    if (process) {
        const hasNullStartDate = process.processWorks.some(work =>
            work.sections.some(section =>
                section.processDetails.some(detail =>
                    detail.processWorkSchedules.some(scheduleArray =>
                        scheduleArray.some(schedule => schedule.startDate == null)
                    )
                )
            )
        );
        if (hasNullStartDate) {
            throw new Error("주간공정표 작업일정의 날짜를 확인해주세요");
        }
    }
    const conferenceMemberIds = conferenceIds.map(member => member.memberId);
    const attendanceMemberIds = attendanceIds.map(member => member.memberId);

    const contentsCopy = { ...contents };
    if(contents.dateDiscussion) {
        contentsCopy.dateDiscussion = dayjs(contents.dateDiscussion).format(dateFormatConstants.DATETIME_FORMAT) 
    }
    if(contents.dateEducation) {
        contentsCopy.dateEducation = dayjs(contents.dateEducation).format(dateFormatConstants.DATETIME_FORMAT) 
    }
    return request({
        url: `${DangerDocApiUrl}/${documentId}`,
        method:'patch',
        data: {
            conferenceType:conferenceType,
            conferenceMemberIds: conferenceMemberIds,
            conferenceFile:conferenceFile,
            attendanceType:attendanceType,
            attendanceMemberIds:attendanceMemberIds,
            attendanceFile:attendanceFile,
            activeSetting: {
                activeWS: items[0].ActiveStatus,
                activeRAT: items[1].ActiveStatus,
                activeRAM: items[2].ActiveStatus,
                activeRAR: items[3].ActiveStatus,
                activeRAE: items[4].ActiveStatus,
                activeRAOI: items[5].ActiveStatus,
            },
            content: contentsCopy,
            process:process,
            startDate:sDate,
            endDate:eDate
            
        }
    })
}


export function DangerListDetailRequest(documentId,fieldType) {
    return request({
        url:`${DangerDocApiUrl}/${documentId}`,
        method:'get',
        params: {
            fieldType:fieldType
        },
    })
}



export function DangerPostFinalRequest(documentId,fieldType) {
    return request({
        url:`${DangerDocApiUrl}/${documentId}/finalize`,
        method:'post',
        data: {
            fieldType:fieldType
        },
    })
}




export function FeedBackStartRequest(documentId) {
    return request({
        url:`${DangerDocApiUrl}/${documentId}/request-feedback`,
        method:'post',
    })
}
export function FeedBackCompleteRequest(documentId,data) {
    return request({
        url:`${DangerDocApiUrl}/${documentId}/complete-feedback`,
        method:'post',
        data: {
            feedbackComment:data
        }
    })
}
export function FeedBackReturnRequest(documentId,data) {
    return request({
        url:`${DangerDocApiUrl}/${documentId}/return-feedback`,
        method:'post',
        data: {
            feedbackComment:data
        }
    })
}

export function DangerExceptionEditRequest(documentId,content,attendanceData) {
    if(content.filePathRAE) {
        content.filePathRAE.uploadDate = dayjs(content.filePathRAE.uploadDate).format(dateFormatConstants.DATETIME_PARTIAL_FORMAT)
    }
    if(content.filePathRAOI) {
        content.filePathRAOI.uploadDate = dayjs(content.filePathRAOI.uploadDate).format(dateFormatConstants.DATETIME_PARTIAL_FORMAT)
    }
    if(content.imagePathRAE) {
        console.log(`output->content.imagePathRAE`,content.imagePathRAE)
        content.imagePathRAE = content.imagePathRAE.map(field => ({
            ...field,
            uploadDate:dayjs(field.uploadDate).format(dateFormatConstants.DATETIME_PARTIAL_FORMAT),
            checkDate:dayjs(field.checkDate).format(dateFormatConstants.DATETIME_PARTIAL_FORMAT),
        }))
    }
    if(content.common.images) {
        content.common.images = content.common.images.map(field => ({
            ...field,
            uploadDate:dayjs(field.uploadDate).format(dateFormatConstants.DATETIME_PARTIAL_FORMAT),
            checkDate:dayjs(field.checkDate).format(dateFormatConstants.DATETIME_PARTIAL_FORMAT),
        }))
    }
    let Education = {
        educationLocation : content.locationEducation,
        educationTarget:content.targetEducation,
        educationStartDate:content.dateEducation,
        attendanceType:attendanceData.attendanceType,
        attendanceCompleteMemberIds:attendanceData.attendanceMemberIds.map(member => member.memberId),
        attendanceFile:attendanceData.attendanceFile,
        educationContent:content.contentsEducation,
        attachFile:content.filePathRAE,
        imageFiles:content.imagePathRAE
    }

    let FieldContent = {
        riskFactors: content.common.riskFactors,
        improvementMeasures: content.common.improvementMeasures,
        images: content.common.images,
        imagesByItem: content.items.map((item) => ({
            itemSort: item.sort,
            imageFiles: (item.picturePathRAOI || []).map(field => ({
                ...field,
                uploadDate: dayjs(field.uploadDate).format(dateFormatConstants.DATETIME_PARTIAL_FORMAT),
                checkDate: dayjs(field.checkDate).format(dateFormatConstants.DATETIME_PARTIAL_FORMAT),
            }))
        })),
        attachFile: content.filePathRAOI
    };

    // let ItemImage = content.items.map((item) => ({
    //     itemSort: item.sort,
    //     imageFiles: (item.picturePathRAOI || []).map(field => ({
    //         ...field,
    //         uploadDate: dayjs(field.uploadDate).format(dateFormatConstants.DATETIME_PARTIAL_FORMAT),
    //         checkDate: dayjs(field.checkDate).format(dateFormatConstants.DATETIME_PARTIAL_FORMAT),
    //     }))
    // }));
    // console.log(`output->content.items[0].picturePathRAOI`,content.items[0].picturePathRAOI)


    return request({
      url:`${DangerDocApiUrl}/${documentId}/partial-content`,
      method:'patch',
      data: {
        spreadEducationContent:Education,
        fieldCheckContent:FieldContent,
      }
    })
  }
  






// 수시 관련 API

export function DangerOccaListRequest(searchCondition) {
    const { startDate , endDate,dateOptions,pagination,size,writeStatus } = searchCondition
    console.log(`output->searchCondition`,searchCondition)
    return request({
      url:`${DangerDocApiUrl}/episode-number/page-info`,
      method:'get',
      params: {
        page:pagination.page,
        size:pagination.size,
        sort:'fieldEpisodeNumber,DESC',
        dateOptions:dateOptions,
        startDate:startDate,
        endDate:endDate,
        episodeStatus:writeStatus
      }
    })
  }
  

  


export function DangerEducationEndRequest(documentId) {
    return request({
      url:`${DangerDocApiUrl}/${documentId}/end-education`,
      method:'post',
      params: {
        isForceEnd: true
      }
    })
  }
  