import request from '../index'

const memberApiBaseUrl = '/members'
const fieldApiBaseUrl = '/fields'
const positionApiBaseUrl = '/member-positions'
const rankApiBaseUrl = '/member-ranks'
const EduApiBaseUrl = '/educations'
const FieldApiBaseUrl = 'fields'


// ID중복체크
export function IDCheckRequest(loginId) {
  return request({
    url: `${memberApiBaseUrl}/${loginId}/check`,
    method: 'get',
  })
}

// 회원목록조회
export function AllMemberListRequest(fieldId,memberName) {
  return request({
    url: `${memberApiBaseUrl}`,
    method: 'get',
    params: {
      fieldId:fieldId,
      memberName:memberName
    }
  })
}

// 회원이름조회
export function MemberNameRequest(memberName) {
  return request({
    url: `${memberApiBaseUrl}`,
    method: 'get',
    params: {
      memberName:memberName,
      page:0,
      size:1000
      // isFetchHeadMembers:false
    }
  })
}

export function MemberIdRequest(loginId) {
  return request({
    url: `${memberApiBaseUrl}/${loginId}`,
    method: 'get',

  })
}



// 직책
export function memberpositionListRequest() {
  return request({
    url: `${positionApiBaseUrl}`,
    method: 'get',
    params: {
      sort:'priorityOrder,asc'
    }
  })
}

// 직급
export function memberrankListRequest() {
  return request({
    url: `${rankApiBaseUrl}`,
    method: 'get',
    params: {
      sort:'priorityOrder,asc'
    }
  })
}


// 현장
export function memberfieldListRequest() {
  return request({
    url: `${FieldApiBaseUrl}`,
    method: 'get',
    params: {
      page:0,
      size:1000,
      fieldType:'FIELD',
      fieldStatus:'ACTIVE'
    }
  })
}

// 직급페이지내 직책조회
export function memberpositionListPageRequest(searchCondition) {
  const {pagination} = searchCondition
  return request({
    url: `${positionApiBaseUrl}`,
    method: 'get',
    params: {
      page : pagination.page,
      size : pagination.size,
      sort:'priorityOrder,asc'
    }
  })
}
// 직급페이지내 직급조회
export function memberrankListPageRequest(searchCondition) {
  const {pagination} = searchCondition
  return request({
    url: `${rankApiBaseUrl}`,
    method: 'get',
    params: {
      page : pagination.page,
      size : pagination.size,
            sort:'priorityOrder,asc'
    }
  })
}

// 교육훈련명페이지내 교육훈련명조회
export function memberEduListPageRequest(searchCondition) {
  const {pagination} = searchCondition
  return request({
    url: `${EduApiBaseUrl}`,
    method: 'get',
    params: {
      page : pagination.page,
      size : pagination.size
    }
  })
}

// 교육훈련명 API

// 추가

export function memberEduPostRequest(name) {
  return request({
    url: `${EduApiBaseUrl}`,
    method: 'post',
    data:{
      name:name,
      isActive:true
    }

  })
}

// 수정
export function memberEduEditRequest(educationId,name) {
  return request({
    url: `${EduApiBaseUrl}/${educationId}`,
    method: 'patch',
    data: {
      name:name,
      isActive:true
    }
  })
}

// 삭제
export function memberEduDeleteRequest(educationId) {
  return request({
    url: `${EduApiBaseUrl}/${educationId}`,
    method: 'delete',
  })
}




// 직책 추가


export function memberpositionPostRequest(positionName) {
  return request({
    url: `${positionApiBaseUrl}`,
    method: 'post',
    data: {
      positionName:positionName
    }
  })
}

// 직급 추가
export function memberrankPostRequest(rankName) {
  return request({
    url: `${rankApiBaseUrl}`,
    method: 'post',
    data: {
      rankName:rankName
    }
  })
}

// 직책 수정

export function memberpositionEditRequest(positionId,positionName) {
  return request({
    url: `${positionApiBaseUrl}/${positionId}`,
    method: 'put',
    data: {
      positionName:positionName
    }
  })
}

// 직급 수정
export function memberrankEditRequest(rankId,rankName) {
  return request({
    url: `${rankApiBaseUrl}/${rankId}`,
    method: 'put',
    data: {
      rankName:rankName
    }
  })
}

// 직급 우선순위 수정
export function memberrankOrderChangeRequest(memberRankPriorityOrders) {
  return request({
    url: `${rankApiBaseUrl}/assign-priority-orders`,
    method: 'post',
    data: {
      memberRankPriorityOrders:memberRankPriorityOrders
    }
  })
}


// 직책 우선순위 수정
export function memberpositionOrderChangeRequest(memberPositionPriorityOrders) {
  return request({
    url: `${positionApiBaseUrl}/assign-priority-orders`,
    method: 'post',
    data: {
      memberPositionPriorityOrders:memberPositionPriorityOrders
    }
  })
}
// 직책 직급 삭제

export function memberpositionDeleteRequest(positionId) {
  return request({
    url: `${positionApiBaseUrl}/${positionId}`,
    method: 'delete',
  })
}

export function memberrankDeleteRequest(rankId) {
  return request({
    url: `${rankApiBaseUrl}/${rankId}`,
    method: 'delete',
  })
}



// 멤버

// 본사조회
export function memberhqListRequest(searchCondition, memberActive) {
  const {
    pagination,
    searchFormat,
    searchText,
    memberLevel,
    approvable,
    startDate,
    endDate,
    memberStatus,
    rankFormat,
    rankId,
    adminLevel,
    accountStatusIn
  } = searchCondition;
  return request({
    url: `${memberApiBaseUrl}`,
    method: "get",
    params: {
      fieldId: 1,
      page: pagination.page,
      size: pagination.size,
      [searchFormat]: searchText,
      memberStatus: memberStatus,
      [rankFormat]: rankId,
      memberLevel: memberLevel,
      approvable: approvable,
      createdStartDate: startDate,
      createdEndDate: endDate,
      adminLevel: adminLevel,
      accountStatus: memberActive,
      accountStatusIn:accountStatusIn
    },
  });
}

// 본사기준 현장조회
export function memberhqtofieldListRequest(searchCondition, memberActive) {
  const {
    pagination,
    searchFormat,
    searchText,
    memberLevel,
    approvable,
    fieldId,
    memberStatus,
    rankFormat,
    rankId,
    startDate,
    endDate,
    adminLevel,
    accountStatusIn
  } = searchCondition;
  return request({
    url: `${memberApiBaseUrl}`,
    method: "get",
    params: {
      isFetchHeadMembers: "false",
      page: pagination.page,
      size: pagination.size,
      [searchFormat]: searchText,
      fieldId: fieldId,
      memberStatus: memberStatus,
      [rankFormat]: rankId,
      memberLevel: memberLevel,
      approvable: approvable,
      createdStartDate: startDate,
      createdEndDate: endDate,
      adminLevel: adminLevel,
      accountStatus: memberActive,
      accountStatusIn:accountStatusIn
    },
  });
}

// 자기현장 현장조회
export function memberfieldtofieldListRequest(searchCondition, fieldId, memberActive) {
  const {
    pagination,
    searchFormat,
    searchText,
    memberLevel,
    approvable,
    memberStatus,
    rankFormat,
    rankId,
    startDate,
    endDate,
    adminLevel,
  } = searchCondition;
  return request({
    url: `${memberApiBaseUrl}`,
    method: "get",
    params: {
      page: pagination.page,
      size: pagination.size,
      [searchFormat]: searchText,
      fieldId: fieldId,
      memberStatus: memberStatus,
      [rankFormat]: rankId,
      memberLevel: memberLevel,
      approvable: approvable,
      createdStartDate: startDate,
      createdEndDate: endDate,
      adminLevel: adminLevel,
      accountStatus: memberActive,
    },
  });
}

// 본사 멤버 생성
export function memberhqPostRequest(dataInfo) {
  return request({
    url: `${memberApiBaseUrl}`,
    method: "post",
    data: {
      fieldId: dataInfo.fieldId,
      loginId: dataInfo.loginId,
      loginPassword: dataInfo.loginPassword,
      loginPasswordCheck: dataInfo.loginPasswordCheck,
      name: dataInfo.name,
      sex: dataInfo.sex,
      birthday: dataInfo.birthday,
      basicAddress: dataInfo.basicAddress,
      detailAddress: dataInfo.detailAddress,
      subPhone: dataInfo.subPhone,
      phone: dataInfo.phone,
      email: dataInfo.email,
      memberPositionId: dataInfo.memberPositionId,
      memberRankId: dataInfo.memberRankId,
      level: dataInfo.level,
      adminLevel: dataInfo.adminLevel,
      status: dataInfo.status,
      approvable: dataInfo.approvable,
      stampFile: dataInfo.stampFile,
      joinedDate: dataInfo.joinedDate,
      systemPosition: dataInfo.systemPosition,
      documentPermissionLevel: dataInfo.documentPermissionLevel,
    },
  });
}

// 멤버조회
export function memberEditListRequest(memberId) {
  return request({
    url: `${memberApiBaseUrl}/${memberId}`,
    method: "get",
  });
}

// 삭제

export function memberDeleteRequest(memberId) {
  return request({
    url: `${memberApiBaseUrl}/${memberId}`,
    method: 'delete',
  })
}

// 수정

export function memberEditRequest(memberId,dataInfo) {
  return request({
    url: `${memberApiBaseUrl}/${memberId}`,
    method: 'put',
    data: {
      fieldId: dataInfo.fieldId,
      loginId: dataInfo.loginId,
      loginPassword: dataInfo.loginPassword,
      loginPasswordCheck: dataInfo.loginPasswordCheck,
      name: dataInfo.name,
      sex: dataInfo.sex,
      birthday: dataInfo.birthday,
      basicAddress: dataInfo.basicAddress,
      detailAddress: dataInfo.detailAddress,
      subPhone: dataInfo.subPhone,
      phone: dataInfo.phone,
      email: dataInfo.email,
      memberPositionId: dataInfo.memberPositionId,
      memberRankId: dataInfo.memberRankId,
      level: dataInfo.level,
      adminLevel: dataInfo.adminLevel,
      status: dataInfo.status,
      approvable: dataInfo.approvable,
      stampFile: dataInfo.stampFile,
      joinedDate: dataInfo.joinedDate,
      systemPosition:dataInfo.systemPosition,
      documentPermissionLevel:dataInfo.documentPermissionLevel
    }
  })
}




//---- 현장 -----

// 조회
export function fieldListRequest(searchCondition) {
  const {pagination,fieldId,managerName,fieldStatus,fieldPeriodType,startDate,endDate} = searchCondition
  return request({
    url: `${fieldApiBaseUrl}`,
    method: "get",
    params: {
      page: pagination.page,
      size: pagination.size,
      id:fieldId,
      managerName:managerName,
      fieldStatus:fieldStatus,
      fieldPeriodType:fieldPeriodType,
      startDate:startDate,
      endDate:endDate,
      fieldType: "FIELD",
    },
  });
}


// 생성
export function fieldPostRequest(dataInfo) {
  return request({
    url: `${fieldApiBaseUrl}`,
    method: 'post',
    data: {
      fieldName:dataInfo.fieldName,
      basicAddress:dataInfo.basicAddress,
      detailAddress:dataInfo.detailAddress,
      managerName:dataInfo.managerName,
      managerSubPhone:dataInfo.managerSubPhone,
      managerPhone:dataInfo.managerPhone,
      managerEmail:dataInfo.managerEmail,
      startDate:dataInfo.startDate,
      endDate:dataInfo.endDate,
      status:dataInfo.status
    }
  })
}
// 서비스종료
export function fieldServiceEndRequest(fieldId,loginId,loginPassword) {
  return request({
    url: `${fieldApiBaseUrl}/${fieldId}/terminate`,
    method: 'post',
    data : {
      id: loginId,
      password: loginPassword
    }
  })
}

// 상세조회
export function fieldEditListRequest(fieldId) {
  return request({
    url: `${fieldApiBaseUrl}/${fieldId}`,
    method: 'get',
  })
}

// 삭제
export function fieldDeleteRequest(fieldId) {
  return request({
    url: `${fieldApiBaseUrl}/${fieldId}`,
    method: 'delete',
  })
}


// 수정
export function fieldEditRequest(fieldId,dataInfo) {
  return request({
    url: `${fieldApiBaseUrl}/${fieldId}`,
    method: 'put',
    data: {
      fieldName:dataInfo.fieldName,
      basicAddress:dataInfo.basicAddress,
      detailAddress:dataInfo.detailAddress,
      managerName:dataInfo.managerName,
      managerSubPhone:dataInfo.managerSubPhone,
      managerPhone:dataInfo.managerPhone,
      managerEmail:dataInfo.managerEmail,
      startDate:dataInfo.startDate,
      endDate:dataInfo.endDate,
      status:dataInfo.status,
    }
  })
}


// 환경설정


export function fieldSettingEditRequest(fieldId,dataInfo) {
  return request({
    url: `${fieldApiBaseUrl}/${fieldId}`,
    method: 'put',
    data: {
      fieldName:dataInfo.fieldName,
      basicAddress:dataInfo.basicAddress,
      detailAddress:dataInfo.detailAddress,
      managerName:dataInfo.managerName,
      managerSubPhone:dataInfo.managerSubPhone,
      managerPhone:dataInfo.managerPhone,
      managerEmail:dataInfo.managerEmail,
      startDate:dataInfo.useStartDate,
      endDate:dataInfo.useEndDate,
      watermarkFile:dataInfo.watermarkFile,
      logoFile:dataInfo.logoFile,
      status:"ACTIVE",
      needLegalAdmin:dataInfo.needLegalAdmin
    }
  })
}

// 현장설정

export function fieldMainSettingRequest(fieldId,dataInfo) {
  const PriceFormat = dataInfo.constructionPrice ? dataInfo.constructionPrice.replace(/,/g, '') : '';
  return request({
    url: `${fieldApiBaseUrl}/${fieldId}/construction`,
    method: 'put',
    data : {
      constructionName: dataInfo.constructionName,
      constructionBasicAddress: dataInfo.constructionBasicAddress,
      constructionDetailAddress : dataInfo.constructionDetailAddress,
      constructionStartDate: dataInfo.constructionStartDate,
      constructionEndDate: dataInfo.constructionEndDate,
      constructionPrice: PriceFormat,
      constructionCompany: dataInfo.constructionCompany,
      orderCompany: dataInfo.orderCompany,
      constructionPercent: dataInfo.constructionPercent
    }
  })
}

export function fieldManagerRequest(fieldId) {
  return request({
    url: `${fieldApiBaseUrl}/${fieldId}/manager`,
    method: 'get',
  })
}




// 선임계 검색용

// 회원목록조회
export function PostMemberListRequest(fieldId,searchType,searchContent) {
  return request({
    url: `${memberApiBaseUrl}`,
    method: 'get',
    params: {
      fieldId:fieldId,
      [searchType]:searchContent
    }
  })
}