import {createApp} from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import PrimeVue from "primevue/config";
// import Print from 'print-js'

import './styles/fonts.scss';
import './styles/base_styles.scss';
import './styles/comm_bar.scss';
import './styles/comm_card.scss';
import './styles/comm_button.scss';
import './styles/comm_form.scss';
import './styles/comm_styles.scss';
import './styles/comm_title_style.scss';
// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import {BootstrapVue3} from 'bootstrap-vue-3';

import '@progress/kendo-ui';
import './kendo-ui-license'; // kendo ui 라이센스 파일
import '@progress/kendo-theme-default/dist/all.css';
import * as am5 from '@amcharts/amcharts5';
import LoadScript from 'vue-plugin-load-script';

import './permission';

import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';


// Date 객체 모킹 스크립트 임포트
// import { overrideDate} from './mockDate';

// overrideDate()


const app = createApp(App);


am5.addLicense('AM5C412151596'); // amcharts 라이센스 등록

createApp(App)
  .use(store)
  .use(router)
  .use(LoadScript)
  .use(BootstrapVue3)
  .use(PrimeVue)
  // .use(IconsPlugin)
  .mount("#app");

