import request from '../index'

const approvalApiBaseUrl = '/approvals'

export function approvalPostRequest(documentId,items) {
    return request({
      url: `${approvalApiBaseUrl}`,
      method: 'post',
      data: {
        documentId:documentId,
        items: items
      }
    })
  }

  export function groupApprovalPostRequest(documentIds,items) {
    return request({
      url: `${approvalApiBaseUrl}/on-multiple-documents`,
      method: 'post',
      data: {
        documentIds:documentIds,
        items: items
      }
    })
  }

  export function approvalStartRequest(approvalId) {
    return request({
      url: `${approvalApiBaseUrl}/${approvalId}/start`,
      method:'post'
    })
  }

  export function approvalNumRequest(){
    return request({
      url: `${approvalApiBaseUrl}/count`,
      method:'get'
    })
  }
  

  export function approvalListRequest(searchCondition) {
    const {pagination,type} = searchCondition
    return request( {
      url: `approval-items/page-info`,
      method:'get',
      params: {
        page: pagination.page,
        size: pagination.size,
        approvalItemBoxType:type
      }
    })
  }

  export function approvalViewRequest(documentId,revisionNumber,documentFolderId) {
    return request( {
      url: `document-histories`,
      method:'get',
      params: {
        documentId: documentId,
        revisionNumber: revisionNumber,
        documentFolderId:documentFolderId
      }
    })
  }

  export function approvalGroupViewRequest(groupDatas) {
    return request( {
      url: `document-histories/multiple`,
      method:'post',
      data: [
        ...groupDatas
      ]
    })
  }



  // export function approvalListRequest(searchCondition) {
  //   const {pagination,type} = searchCondition
  //   return request( {
  //     url: `${approvalApiBaseUrl}`,
  //     method:'get',
  //     params: {
  //       page: pagination.page,
  //       size: pagination.size,
  //       type:type
  //     }
  //   })
  // }



  export function approvalApproveRequest(approvalItemId){
    return request({
      url: `${approvalApiBaseUrl}/items/${approvalItemId}/approve`,
      method:'patch'
    })
  }
  export function approvalReturnRequest(approvalItemId,reason){
    return request({
      url: `${approvalApiBaseUrl}/items/${approvalItemId}/return`,
      method:'patch',
      data:{
        returnReason:reason
      }
    })
  }

  export function approvalSelfReturnRequest(approvalItemId){
    return request({
      url: `${approvalApiBaseUrl}/${approvalItemId}/return-self`,
      method:'post',
    })
  }


export function approvalIdListRequest(approvalId){
  return request({
    url: `${approvalApiBaseUrl}/${approvalId}`,
    method:'get'
  })
}




export function approvalTemplateRequest(prefix,documentId) {
  return request({
    url: `${prefix}/${documentId}`,
    method: 'get'
  })
}




export function approvalReadCheckRequest(approvalItemId) {
  return request( {
    url: `approval-items/${approvalItemId}/read`,
    method:'post',
  })
}


