import request from '../../index'

const paymentDocApiBaseUrl = 'documents/site-safety'

export function PatchsafeFieldDocumentRequest(documentId,activeSetting,formData) {
  const safeCheckDate = new Date(formData.safeCheckDate);
  // 날짜를 'YYYY-MM-DD HH:mm:ss' 형식으로 포맷팅
  const formattedSafeCheckDate = safeCheckDate.toISOString().slice(0, 19).replace('T', ' ');

  return request({
    url: `${paymentDocApiBaseUrl}/${documentId}`,
    method: 'patch',
    data: {
        activeSetting: {
            typeRR:activeSetting.typeRR,
            typeCR:activeSetting.typeCR
        },

        content:{
          ...formData,
          safeCheckDate: formattedSafeCheckDate
        }

    }
  })
}

// export function ViewsafeFieldDocumentListRequest(searchCondition) {
//   return request({
//     url: `${paymentDocApiBaseUrl}`,
//     method: 'get',
//     params: {
//       documentFolderId : searchCondition.documentFolderId,
//       page:searchCondition.pagination.page,
//       size:searchCondition.pagination.size,
//     }
//   })
// }

export function ViewsafeFieldDocumentListRequest(searchCondition) {
  const {documentFolderId, fieldId,type,pagination,startDate,writeStatus,writer,
      endDate,feedbackType} = searchCondition
  return request({
      url: `${paymentDocApiBaseUrl}`,
      method:'get',
      params: {
          documentFolderId : documentFolderId,
          fieldId: fieldId,
          startDate: startDate,
          endDate: endDate,
          // type: type,
          writer:writer,
          writeStatus: writeStatus,
          page: pagination.page,
          size: pagination.size,
          feedbackType:feedbackType
      }
  })
}

export function ViewsafeFieldDocumentBoardRequest(documentId) {
  return request({
    url: `${paymentDocApiBaseUrl}/${documentId}`,
    method: 'get',
  })
}



export function RequestsafeFieldDocumentBoardRequest(documentId) {
  return request({
    url: `${paymentDocApiBaseUrl}/${documentId}/request-feedback`,
    method: 'post',
  })
}

export function CompletesafeFieldDocumentBoardRequest(documentId) {
  return request({
    url: `${paymentDocApiBaseUrl}/${documentId}/complete-feedback`,
    method: 'post',
  })
}

