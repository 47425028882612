<template>
  <div class="sidebar">
    <div class="top-nav-logo">
      <img id="logo-img" src = "../../assets/images/layout_icons/logo_truesafe_top.png" alt="Error" @click="MainEvent()">
    </div>
    <!--User Information-->
    <div id="sidebar-login-info-box">
      <div id="side-login-topcolorbar" />
      <div class="logined-wrapper">
        <div class="loigined-info-title-wrap">
          <!-- <div class="loigined-info-title" v-if="isFieldType == 'HEAD'">본사 사용자</div>
          <div class="loigined-info-title" v-if="isFieldType == 'FIELD'">현장 사용자</div> -->
          <div class="loigined-info-title">이용자 정보</div>
        </div>
        <div class="logined-location-wrap">

          <div class="logined-location-inner-wrap" style="justify-content: flex-start; width: 70%;">
            <span class="logined-location-text-info">{{RTHeadName}} </span>
          </div>

          <div class="logined-location-inner-wrap"
          style="justify-content: flex-end; width: 30%"
          v-if="isAdminCheck == 'ADMIN' || isAdminCheck== 'SUPER_ADMIN'">
            <button class ="btn-option-box" @click="$router.push('/ca_config/branchConf')">관리</button>
          </div>

        </div>
        <span class="logined-location-text-info" v-if="isFieldType == 'FIELD'" :title="RTFieldName">{{RTFieldName}} </span>

        <span class="logined-location-text-info" v-if="isFieldType == 'HEAD'">본사 시스템 그룹 사용자 </span>

        <div class="logined-user-info-wrap">
          <span style=" font-size: 16px;" class="logined-user-position">{{RTPositionName}} </span>
          <span style=" font-size: 16px;" class="logined-user-name" :title="RTMemberName">{{RTMemberName}} </span>
          <span style=" font-size: 14px;" class="logined-user-other ">님</span>
          <button class="logined-user-config">
            <span class="icon-i_gear " style="font-size: 20px;" @click="this.$router.push({name:'my_setting'})"></span>
          </button>

        </div>

        <div class="logined-button-wrap">
          <button class="logined-button" @click="handleLogoutBtnClick">로그아웃</button>
        </div>
      </div>
    </div>

    <!--User Document Menu-->
    <div id="document-list-box" class="document-list-box">
      <div id="document-title-box" class="approval-box" @click="layoutDownEvent">
        <div class="left">
          <div class="figure-circle" />
          <span id="document-title-name"> 문서결재</span>
        </div>
        <div class="right">
          <i class="arrow fa fa-angle-right pull-right"></i>
        </div>
      </div>
      <div class="document-list-wrap">
        <ul class="document-list">
          <li><a class="document-list-menu" @click="pending">결재 대기함 ( {{ computedPendingCount.totalCount }} 건)
            <span class="introCircle" v-if="computedPendingCount.totalCount > 0"></span>
            </a></li>
          <li><a class="document-list-menu" @click="progress">결재 진행함 ( {{ computedInProgressCount.totalCount }} 건)
            <!-- <span class="introCircle" v-if="computedInProgressCount.unreadCount > 0">New</span> -->
            </a></li>
          <li><a class="document-list-menu" @click="approve">결재 완료함 ( {{ computedApproveCount.totalCount }} 건)
            <!-- <span class="introCircle" v-if="computedApproveCount.unreadCount > 0">New</span> -->
            </a></li>
          <li><a class="document-list-menu" @click="returnE">회수/반려 문서함 ( {{ computedReturnCount.totalCount }} 건)
            <!-- <span class="introCircle" v-if="computedReturnCount.unreadCount > 0">New</span> -->
            </a></li>
        </ul>
      </div>

    </div>

    <!--User service Menu Title-->
    <div id="service-menu-title-box">
      <div class="figure-circle" />
      <span id="service-menu-title-name"> 서비스메뉴</span>
    </div>



    <!--User service Menu List-->
    <!--
    <h1>test   {{ input1 }}</h1>
    <input type="text" v-model="input1" />
    <button type="button" @click="getData">GET</button>
    <button @click="$router.push('ca_home')">
      HomePage
    </button>
    <button @click="$router.push('ca_scheduler')">
      AboutPage
    </button>

    <div v-if="input1 == 'abc'">
    <button>test1</button>
    </div>
    <div v-else>
    <button>test2</button>
    </div>

    -->
    <!-- <div class="service-menu-box">
      <b-nav vertical pills>
        <b-nav-item to="/ca_home" exact exact-active-class="active-route2"> -->
          <!--<img src="../../assets/images/menu_icon/icon_home.png" height="30" width="30"/>-->
          <!-- <b-icon icon="clipboard"></b-icon>
          대시보드
        </b-nav-item>

        <b-nav-item>
          <div v-b-toggle.plan-collapse>
            <b-icon icon="calendar2-check"></b-icon> 스마트 스케줄러
          </div>
          <b-collapse id="plan-collapse" accordion="my-accordion" class="mt-1">
            <b-nav-item to="/ca_plan/plan_list" exact exact-active-class="active-route">일정현황</b-nav-item>
            <b-nav-item to="/ca_plan/plan_create" exact exact-active-class="active-route">일정 계획하기</b-nav-item>
            <b-nav-item to="/ca_plan/plan_excute" exact exact-active-class="active-route">일정 실행하기</b-nav-item>
          </b-collapse>
        </b-nav-item>

        <b-nav-item>
          <div v-b-toggle.work-collapse>
            <b-icon icon="calendar2-check"></b-icon> 핵심업무 관리
          </div>
          <b-collapse id="work-collapse" accordion="my-accordion" class="mt-1">
            <b-nav-item to="/ca_work/work_risk_evalution" exact exact-active-class="active-route">위험성평가</b-nav-item>
            <b-nav-item to="/ca_work/work_edu_tranning" exact exact-active-class="active-route">교육훈련</b-nav-item>
            <b-nav-item to="/ca_work/work_branch_activity" exact exact-active-class="active-route">현장점검 활동</b-nav-item>
            <b-nav-item to="/ca_work/work_head_activity" exact exact-active-class="active-route">본사점검 활동</b-nav-item>
          </b-collapse>
        </b-nav-item>

        <b-nav-item>
          <div v-b-toggle.policy-collapse>
            <b-icon icon="calendar2-check"></b-icon> 안전보건 경영방침
          </div>
          <b-collapse id="policy-collapse" accordion="my-accordion" class="mt-1">
            <b-nav-item to="/ca_policy/policy_list" exact exact-active-class="active-route">방침 및 목표보기</b-nav-item>
            <b-nav-item to="/ca_policy/policy_operate" exact exact-active-class="active-route">목표 및 추진계획 관리</b-nav-item>
          </b-collapse>
        </b-nav-item>

        <b-nav-item>
          <div v-b-toggle.management-collapse>
            <b-icon icon="calendar2-check"></b-icon> 산업안전보건관리비
          </div>
          <b-collapse id="management-collapse" accordion="my-accordion" class="mt-1">
            <b-nav-item to="/ca_management/management_list" exact exact-active-class="active-route">사용계획 보기</b-nav-item>
            <b-nav-item to="/ca_management/management_operate" exact exact-active-class="active-route">사용내역서 관리</b-nav-item>
          </b-collapse>
        </b-nav-item>

        <b-nav-item>
          <div v-b-toggle.board-collapse>
            <b-icon icon="calendar2-check"></b-icon> 안전소통의 방
          </div>
          <b-collapse id="board-collapse" accordion="my-accordion" class="mt-1">
            <b-nav-item to="/ca_board/board_head" exact exact-active-class="active-route">본사 공지 게시판</b-nav-item>
            <b-nav-item to="/ca_board/board_branch" exact exact-active-class="active-route">현장 게시판</b-nav-item>
            <b-nav-item to="/ca_board/board_comm" exact exact-active-class="active-route">소통 게시판</b-nav-item>
          </b-collapse>
        </b-nav-item>

        <b-nav-item>
          <div v-b-toggle.kosha-collapse>
            <b-icon icon="calendar2-check"></b-icon> KOSHA-MS 관리
          </div>
          <b-collapse id="kosha-collapse" accordion="my-accordion" class="mt-1">
            <b-nav-item to="/ca_kosha/kosha_emanual" exact exact-active-class="active-route">E-manual</b-nav-item>
            <b-nav-item to="/ca_kosha/kosha_head_com" exact exact-active-class="active-route">본사공유 문서</b-nav-item>
            <b-nav-item to="/ca_kosha/kosha_branch_com" exact exact-active-class="active-route">현장등록 문서</b-nav-item>
            <b-nav-item to="/ca_kosha/kosha_doclist" exact exact-active-class="active-route">KOSHA-MS 문서 리스트</b-nav-item>
          </b-collapse>
        </b-nav-item>
      </b-nav>
    </div> -->


    <div class="service-menu-box">
      <aside class="asideBar">
        <div id="leftside_navigation" class="nano">
          <ul class="nano-container">
            <router-link to="/ca_home" v-if="isFieldType == 'FIELD'">
              <li class="dashboard-container"  @click="dropEvent(1)"
            :class="{active:this.$store.state.dropLayout == 1}">

                <a>
                  <!-- <b-icon icon="clipboard"></b-icon> -->
                  <!-- <span class="icon-i_dash" style="font-size: 25px; float: left"></span> -->
                  <img src="@/assets/images/menu_icons/d_dashboard.png" alt="대시보드" style="height:25px; float:left; padding-left:4%" class="img-inactive">
                  <img src="@/assets/images/menu_icons/a_dashboard.png" alt="대시보드" style="height:25px; float:left; padding-left:4%" class="img-active">
                  <!--<span class="icon-i_dash" style="font-size: 24px; display: table-cell; vertical-align: middle;"></span>-->
                  <span>대시보드</span>
                </a>

              </li>
            </router-link>

            <div class="sub-menu" v-if="isFieldType == 'HEAD'"
              :class="{active:this.$store.state.dropLayout == 1}">
              <a id="0" class="submenu-main-container" href="javascript:void(0);"
              @click="dropEvent(1)"
              :class="{active:this.$store.state.menuActive == '대시보드'}">
                <!-- <b-icon icon="calendar2-check"></b-icon> -->
                <!-- <span class="icon-i_dash" style="font-size: 25px; float: left"></span> -->
                <img src="@/assets/images/menu_icons/d_dashboard.png" alt="대시보드" style="height:25px; float:left; padding-left:4%" class="img-inactive">
                  <img src="@/assets/images/menu_icons/a_dashboard.png" alt="대시보드" style="height:25px; float:left; padding-left:4%" class="img-active">
                <span id="0">대시보드</span>
                <i id="0" class="arrow fa fa-angle-right pull-right" :class="{active:this.$store.state.dropLayout == 1}"></i>
              </a>
              <!-- 본사 계정에만 표시 -->
              <ul class="submenu-container" :class="{active:this.$store.state.dropLayout == 1}">
                <!-- <router-link to="/ca_scene"> -->
                <li class="submenu-drop" :class="{bgcF:this.$store.state.menuProgress == 1 && this.$store.state.menuActive == '대시보드'}"  @click="branchRoute('모니터링','대시보드')"><a>현장 모니터링</a></li>
                <!-- </router-link> -->
                <!-- <router-link to="/ca_head"> -->
                <li class="submenu-drop" :class="{bgcF:this.$store.state.menuProgress == 2 && this.$store.state.menuActive == '대시보드'}"  @click="branchRoute('종합현황','대시보드')"><a>본사 종합 현황</a></li>
                <!-- </router-link> -->

              </ul>
            </div>

            <!-- <router-link to="/ca_plan/plan_list"> -->
            <li class="plan-container" @click="dropEvent(2)"
            :class="{active:this.$store.state.menuActive == '스케쥴러'}">

              <a>
                <!-- <b-icon icon="clipboard"></b-icon> -->
                <!-- <span class="icon-i_plan" style="font-size: 25px; float: left"></span> -->
                <img src="@/assets/images/menu_icons/d_scheduler.png" alt="스케줄러" style="height:25px; float:left; padding-left:4%" class="img-inactive">
                <img src="@/assets/images/menu_icons/a_scheduler.png" alt="스케줄러" style="height:25px; float:left; padding-left:4%" class="img-active">
                <!--<span class="icon-i_dash" style="font-size: 24px; display: table-cell; vertical-align: middle;"></span>-->
                <span class="plan-text">스마트 스케쥴러</span>
              </a>
            </li>
            <!-- </router-link> -->
              <!-- <li class="sub-menu">
                현장 사이드 메뉴
                <a id="0"
                class="submenu-main-container"
                href="javascript:void(0);"
                v-if="isFieldType == 'FIELD'"
                @click="getMenuEventNavigationReset">
                  <b-icon icon="calendar2-check"></b-icon>
                  <span class="icon-i_plan" style="font-size: 25px; float: left"></span>
                  <span id="0">스마트 스케쥴러</span>
                  <i id="0" class="arrow fa fa-angle-right pull-right"></i>
                </a> -->

                <!-- 본사 사이드 메뉴 -->
                <!-- <a id="1"
                class="submenu-main-container"
                href="javascript:void(0);"
                v-if="isFieldType == 'HEAD'"
                @click="getMenuEventNavigationReset">
                  <b-icon icon="calendar2-check"></b-icon>
                  <span class="icon-i_plan" style="font-size: 25px; float: left"></span>
                  <span id="1">스마트 스케쥴러</span>
                  <i id="1" class="arrow fa fa-angle-right pull-right"></i>
                </a>

                <ul class="submenu-container">
                  <router-link to="/ca_plan/plan_list">
                    <li class="submenu-drop"><a>일정현황</a></li>
                  </router-link>

                  <router-link to="/ca_plan/plan_create">
                    <li class="submenu-drop"  @click="NoPage"><a>일정 계획하기</a></li>
                  </router-link>

                  <router-link to="/ca_plan/plan_excute">
                    <li class="submenu-drop" @click="NoPage"><a>일정 실행하기</a></li>
                  </router-link>

                </ul>
              </li>
            </router-link> -->

            <li class="sub-menu"
            :class="{active:this.$store.state.dropLayout == 3}"
            >
              <!-- 현장 사이드 메뉴 -->
              <a id="0"
              class="submenu-main-container"
              href="javascript:void(0);"
              v-if="isFieldType == 'FIELD'"
              @click="dropEvent(3)"
              :class="{active:this.$store.state.dropLayout == 3}"
              >
                <!-- <b-icon icon="calendar2-check"></b-icon> -->
                <!-- <span class="icon-i_work" style="font-size: 25px; float: left"></span> -->
                  <img src="@/assets/images/menu_icons/d_mainwork.png" alt="핵심업무" style="height:25px; float:left; padding-left:4%" class="img-inactive">
                  <img src="@/assets/images/menu_icons/a_mainwork.png" alt="핵심업무" style="height:25px; float:left; padding-left:4%" class="img-active">
                <span id="0" style="position:relative">
                  핵심업무 관리
                <div class="introCircle" v-if="this.$store.state.safetyDocumentCount > 0"></div>

                </span>
                <i id="0" class="arrow fa fa-angle-right pull-right" :class="{active:this.$store.state.dropLayout == 3}"></i>
              </a>

              <!-- 본사 사이드 메뉴 -->
              <a id="1"
              class="submenu-main-container"
              href="javascript:void(0);"
              v-if="isFieldType == 'HEAD'"
              @click="dropEvent(3)"
              :class="{active:this.$store.state.dropLayout == 3}"
              >
                <!-- <b-icon icon="calendar2-check"></b-icon> -->
                <!-- <span class="icon-i_work" style="font-size: 25px; float: left"></span> -->
                <img src="@/assets/images/menu_icons/d_mainwork.png" alt="핵심업무" style="height:25px; float:left; padding-left:4%" class="img-inactive">
                <img src="@/assets/images/menu_icons/a_mainwork.png" alt="핵심업무" style="height:25px; float:left; padding-left:4%" class="img-active">
                <span id="1" style="position:relative; display:flex;">
                  핵심업무 관리
                  <div class="introCircle" v-if="this.$store.state.riskDocumentCount > 0"></div>
                </span>
                <i id="1" class="arrow fa fa-angle-right pull-right" :class="{active:this.$store.state.dropLayout == 3}"></i>

              </a>

              <ul class="submenu-container" :class="{active:this.$store.state.dropLayout == 3}">
                <!-- <router-link to="/ca_work/work_risk_evalution"> -->
                  <li class="submenu-drop" :class="{bgcF:this.$store.state.menuProgress == 2 && this.$store.state.menuActive == '핵심업무'}"  @click="branchRoute('교육훈련','핵심업무')"><a>교육훈련</a></li>
                <!-- </router-link> -->

                <!-- <router-link to="/ca_work/work_edu_tranning"> -->
                  <li class="submenu-drop" :class="{bgcF:this.$store.state.menuProgress == 1 && this.$store.state.menuActive == '핵심업무'}"  @click="branchRoute('위험성평가','핵심업무')"><a>위험성평가</a></li>

                <!-- </router-link> -->

                <!-- <router-link to="/ca_work/work_branch_activity" > -->
                <!-- <li class="submenu-drop" v-if="this.$store.state.loginMember.field.type == 'FIELD'" :class="{bgcF:this.$store.state.menuProgress == 3 && this.$store.state.menuActive == '핵심업무'}"  @click="branchRoute('문서피드백','핵심업무')">
                  <a>문서피드백</a>
                  <span class="introCircle"  style="color:White; right:10px;" v-if="computedUnReadSafetyDocumentListRequest.unReadDocumentsCount > 0">New</span>
                </li> -->
                <li class="submenu-drop" v-if="this.$store.state.loginMember.field.type == 'FIELD'" :class="{bgcF:this.$store.state.menuProgress == 4 && this.$store.state.menuActive == '핵심업무'}"  @click="branchRoute('본사점검활동','핵심업무')">
                  <a>본사점검활동</a>
                  <span class="introCircle"  style="color:White; right:96px; top:35%;" v-if="this.$store.state.safetyDocumentCount > 0" ></span>
                  <!-- -->
                  </li>
                <!-- </router-link> -->

                <!-- <router-link to="/ca_work/work_head_activity" > -->
                <li class="submenu-drop" v-if="this.$store.state.loginMember.field.type == 'HEAD'" :class="{bgcF:this.$store.state.menuProgress == 3 && this.$store.state.menuActive == '핵심업무'}"  @click="branchRoute('위험성평가 피드백','핵심업무')">
                  <a>위험성평가 피드백</a>
                  <span class="introCircle" style="color:White; right:66px; top:35%;" v-if="this.$store.state.riskDocumentCount > 0"></span>
                </li>

                <!-- </router-link> -->

              </ul>
            </li>

            <li class="sub-menu"
            :class="{active:this.$store.state.dropLayout == 4}">
              <!-- 현장 사이드 메뉴 -->
              <a id="1"
              class="submenu-main-container"
              href="javascript:void(0);"
              v-if="isFieldType == 'FIELD'"
              @click="dropEvent(4)"
              :class="{active:this.$store.state.dropLayout == 4}"
              >
                <!-- <b-icon icon="calendar2-check"></b-icon> -->
                <!-- <span class="icon-i_policy" style="font-size: 25px; float: left"></span> -->
                <img src="@/assets/images/menu_icons/d_safetyprice.png" alt="관리비" style="height:25px; float:left; padding-left:4%" class="img-inactive">
                <img src="@/assets/images/menu_icons/a_safetyprice.png" alt="관리비" style="height:25px; float:left; padding-left:4%" class="img-active">
                <span id="1">안전보건 경영방침</span>
                <i id="1" class="arrow fa fa-angle-right pull-right" :class="{active:this.$store.state.dropLayout == 4}"></i>
              </a>

              <!-- 본사 사이드 메뉴 -->
              <a id="2"
              class="submenu-main-container"
              href="javascript:void(0);"
              v-if="isFieldType == 'HEAD'"
              @click="dropEvent(4)"
              :class="{active:this.$store.state.dropLayout == 4}"
              >
                <!-- <b-icon icon="calendar2-check"></b-icon> -->
                <!-- <span class="icon-i_policy" style="font-size: 25px; float: left"></span> -->
                <img src="@/assets/images/menu_icons/d_safetyprice.png" alt="관리비" style="height:25px; float:left; padding-left:4%" class="img-inactive">
                <img src="@/assets/images/menu_icons/a_safetyprice.png" alt="관리비" style="height:25px; float:left; padding-left:4%" class="img-active">
                <span id="2">안전보건 경영방침</span>
                <i id="2" class="arrow fa fa-angle-right pull-right" :class="{active:this.$store.state.dropLayout == 4}"></i>
              </a>

              <ul class="submenu-container" :class="{active:this.$store.state.dropLayout == 4}">
                <!-- <router-link to="/ca_policy/policy_list"> -->
                <li class="submenu-drop" :class="{bgcF:this.$store.state.menuProgress == 1 && this.$store.state.menuActive == '경영방침'}"  @click="branchRoute('방침','경영방침')"><a>방침 및 목표</a></li>
                <!-- </router-link> -->

                <!-- <router-link to="/ca_policy/policy_operate"> -->
                <li class="submenu-drop" :class="{bgcF:this.$store.state.menuProgress == 2 && this.$store.state.menuActive == '경영방침'}"  @click="branchRoute('중점업무','경영방침')" v-if="this.$store.state.loginMember.field.type=='HEAD'"><a>안전보건 중점업무</a></li>
                <!-- </router-link> -->

                <!-- <router-link to="/ca_policy/policy_operate"> -->
                <li class="submenu-drop" :class="{bgcF:this.$store.state.menuProgress == 2 && this.$store.state.menuActive == '경영방침'}"  @click="branchRoute('중점업무','경영방침')" v-if="this.$store.state.loginMember.field.type=='FIELD'"><a>목표 및 추진계획</a></li>
                <!-- </router-link> -->

              </ul>
            </li>

            <li class="sub-menu"
            :class="{active:this.$store.state.dropLayout == 5}">
              <!-- 현장 사이드 메뉴 -->
              <a id="2"
              class="submenu-main-container"
              href="javascript:void(0);"
              v-if="isFieldType == 'FIELD'"
              @click="dropEvent(5)"
              :class="{active:this.$store.state.dropLayout == 5}"
              >
                <!-- <b-icon icon="calendar2-check"></b-icon> -->
                <!-- <span class="icon-i_management" style="font-size: 25px; float: left"></span> -->
                <img src="@/assets/images/menu_icons/d_safetyhealth.png" alt="안전보건" style="height:25px; float:left; padding-left:4%" class="img-inactive">
                <img src="@/assets/images/menu_icons/a_safetyhealth.png" alt="안전보건" style="height:25px; float:left; padding-left:4%" class="img-active">
                <span id="2">산업안전보건관리비</span>
                <i id="2" class="arrow fa fa-angle-right pull-right" :class="{active:this.$store.state.dropLayout == 5}"></i>
              </a>

              <!-- 본사 사이드 메뉴 -->
              <a id="3"
              class="submenu-main-container"
              href="javascript:void(0);"
              v-if="isFieldType == 'HEAD'"
              @click="dropEvent(5)"
              :class="{active:this.$store.state.dropLayout == 5}">
                <!-- <b-icon icon="calendar2-check"></b-icon> -->
                <!-- <span class="icon-i_management" style="font-size: 25px; float: left"></span> -->
                <img src="@/assets/images/menu_icons/d_safetyhealth.png" alt="안전보건" style="height:25px; float:left; padding-left:4%" class="img-inactive">
                <img src="@/assets/images/menu_icons/a_safetyhealth.png" alt="안전보건" style="height:25px; float:left; padding-left:4%" class="img-active">
                <span id="3">산업안전보건관리비</span>
                <i id="3" class="arrow fa fa-angle-right pull-right" :class="{active:this.$store.state.dropLayout == 5}"></i>
              </a>

              <ul class="submenu-container" :class="{active:this.$store.state.dropLayout == 5}">
                <!-- <router-link to="/ca_management/management_list"> -->
                <li class="submenu-drop"  :class="{bgcF:this.$store.state.menuProgress == 1 && this.$store.state.menuActive == '보건관리비'}" @click="branchRoute('사용계획','보건관리비')"><a>사용계획 보기</a></li>
                <!-- </router-link> -->

                <!-- <router-link to="/ca_management/management_operate"> -->
                <li class="submenu-drop"  :class="{bgcF:this.$store.state.menuProgress == 2 && this.$store.state.menuActive == '보건관리비'}" @click="branchRoute('사용내역서','보건관리비')"><a>사용내역서 보기</a></li>
                <!-- </router-link> -->

              </ul>
            </li>

            <li class="sub-menu"
            :class="{active:this.$store.state.dropLayout == 6}">
              <!-- 현장 사이드 메뉴 -->
              <a id="3"
              class="submenu-main-container"
              href="javascript:void(0);"
              v-if="isFieldType == 'FIELD'"
              @click="dropEvent(6)"
              :class="{active:this.$store.state.dropLayout == 6}">
                <!-- <b-icon icon="calendar2-check"></b-icon> -->
                <!-- <span class="icon-i_board" style="font-size: 25px; float: left"></span> -->
                <img src="@/assets/images/menu_icons/d_safetyboard.png" alt="게시판" style="height:25px; float:left; padding-left:4%" class="img-inactive">
                <img src="@/assets/images/menu_icons/a_safetyboard.png" alt="게시판" style="height:25px; float:left; padding-left:4%" class="img-active">
                <span id="3">안전소통의 방</span>
                <i id="3" class="arrow fa fa-angle-right pull-right" :class="{active:this.$store.state.dropLayout == 6}"></i>
              </a>

              <!-- 본사 사이드 메뉴 -->
              <a id="4"
              class="submenu-main-container"
              href="javascript:void(0);"
              v-if="isFieldType == 'HEAD'"
              @click="dropEvent(6)"
              :class="{active:this.$store.state.dropLayout == 6}">
                <!-- <b-icon icon="calendar2-check"></b-icon> -->
                <!-- <span class="icon-i_board" style="font-size: 25px; float: left"></span> -->
                <img src="@/assets/images/menu_icons/d_safetyboard.png" alt="게시판" style="height:25px; float:left; padding-left:4%" class="img-inactive">
                <img src="@/assets/images/menu_icons/a_safetyboard.png" alt="게시판" style="height:25px; float:left; padding-left:4%" class="img-active">
                <span id="4">안전소통의 방</span>
                <i id="4" class="arrow fa fa-angle-right pull-right" :class="{active:this.$store.state.dropLayout == 6}"></i>
              </a>

              <ul class="submenu-container" :class="{active:this.$store.state.dropLayout == 6}">
                <!-- <router-link to="/ca_board/board_head"> -->
                <li class="submenu-drop " :class="{bgcF:this.$store.state.menuProgress == 1 && this.$store.state.menuActive == '게시판'}" @click="branchRoute('b_HEAD','게시판')"><a>본사 공지 게시판</a></li>
                <!-- </router-link> -->


                <li class="submenu-drop" :class="{bgcF:this.$store.state.menuProgress == 2 && this.$store.state.menuActive == '게시판'}" @click="branchRoute('b_FIELD','게시판')"><a>현장 게시판</a></li>


                <!-- <router-link to="/ca_board/board_comm"> -->
                <li class="submenu-drop" :class="{bgcF:this.$store.state.menuProgress == 3 && this.$store.state.menuActive == '게시판'}"  @click="branchRoute('b_COMM','게시판')"><a>소통 게시판</a></li>
                <!-- </router-link> -->

              </ul>
            </li>

            <li class="sub-menu"
            :class="{active:this.$store.state.dropLayout == 7}">
              <!-- 현장 사이드 메뉴 -->
              <a id="4"
              class="submenu-main-container"
              href="javascript:void(0);"
              v-if="isFieldType == 'FIELD'"
              @click="dropEvent(7)"
              :class="{active:this.$store.state.dropLayout == 7}">
                <!-- <b-icon icon="calendar2-check"></b-icon> -->
                <!-- <span class="icon-i_kosha" style="font-size: 25px; float: left"></span> -->
                <img src="@/assets/images/menu_icons/d_koshams.png" alt="kosha" style="height:25px; float:left; padding-left:4%" class="img-inactive">
                <img src="@/assets/images/menu_icons/a_koshams.png" alt="kosha" style="height:25px; float:left; padding-left:4%" class="img-active">
                <span id="4">KOSHA-MS 관리</span>
                <i id="4" class="arrow fa fa-angle-right pull-right" :class="{active:this.$store.state.dropLayout == 7}"></i>
              </a>

              <!-- 본사 사이드 메뉴 -->
              <a id="5"
              class="submenu-main-container"
              href="javascript:void(0);"
              v-if="isFieldType == 'HEAD'"
              @click="dropEvent(7)"
              :class="{active:this.$store.state.dropLayout == 7}">
                <!-- <b-icon icon="calendar2-check"></b-icon> -->
                <!-- <span class="icon-i_kosha" style="font-size: 25px; float: left"></span> -->
                <img src="@/assets/images/menu_icons/d_koshams.png" alt="kosha" style="height:25px; float:left; padding-left:4%" class="img-inactive">
                <img src="@/assets/images/menu_icons/a_koshams.png" alt="kosha" style="height:25px; float:left; padding-left:4%" class="img-active">
                <span id="5">KOSHA-MS 관리</span>
                <i id="5" class="arrow fa fa-angle-right pull-right" :class="{active:this.$store.state.dropLayout == 7}"></i>
              </a>

              <ul class="submenu-container" :class="{active:this.$store.state.dropLayout == 7}">
                <!-- <router-link to="/ca_kosha/kosha_emanual"> -->
                <li class="submenu-drop" :class="{bgcF:this.$store.state.menuProgress == 1 && this.$store.state.menuActive == 'KOSHA'}"  @click="branchRoute('E-manual','KOSHA')"><a>KOSHA-MS 메뉴얼</a></li>
                <!-- </router-link> -->

                <!-- <router-link to="/ca_kosha/kosha_head_com"> -->
                <li class="submenu-drop" v-if="this.$store.state.loginMember.field.type =='HEAD'" :class="{bgcF:this.$store.state.menuProgress == 2 && this.$store.state.menuActive == 'KOSHA'}"  @click="branchRoute('문서구축현황','KOSHA')"><a>KOSHA-MS 현황(본사)</a></li>
                <li class="submenu-drop" v-if="this.$store.state.loginMember.field.type =='FIELD'" :class="{bgcF:this.$store.state.menuProgress == 2 && this.$store.state.menuActive == 'KOSHA'}"  @click="branchRoute('문서구축현황','KOSHA')"><a>KOSHA-MS 현황</a></li>
                <!-- <li class="submenu-drop" :class="{bgcF:this.$store.state.menuProgress == 3 && this.$store.state.menuActive == 'KOSHA'}"  @click="branchRoute('본사공유문서','KOSHA')"><a>본사공유 문서</a></li> -->
                <!-- </router-link> -->

                <!-- <router-link to="/ca_kosha/kosha_branch_com"> -->
                <!-- <li class="submenu-drop" :class="{bgcF:this.$store.state.menuProgress == 4 && this.$store.state.menuActive == 'KOSHA'}"  @click="branchRoute('현장기초문서','KOSHA')" v-if="this.$store.state.loginMember.field.type == 'FIELD'"><a>현장기초 문서</a></li> -->
                <li class="submenu-drop" v-if="this.$store.state.loginMember.field.type == 'HEAD'" :class="{bgcF:this.$store.state.menuProgress == 4 && this.$store.state.menuActive == 'KOSHA'}"  @click="branchRoute('KOSHA-MS 현황(현장)','KOSHA')"><a>KOSHA-MS 현황(현장)</a></li>
                <!-- </router-link> -->

                <!-- <router-link to="/ca_kosha/kosha_doclist"> -->
                <li class="submenu-drop" :class="{bgcF:this.$store.state.menuProgress == 5 && this.$store.state.menuActive == 'KOSHA'}"  @click="branchRoute('KOSHA-MS 문서 리스트','KOSHA')"><a>KOSHA-MS 문서 리스트</a></li>
                <!-- </router-link> -->

              </ul>
            </li>

          </ul>
        </div>
      </aside>
    </div>

  <!--End-->
  </div>
</template>

<script>
import { logoutRequest } from "@/api/member/member-api.js";
import { approvalNumRequest } from '@/api/approval/approval-api.js'

export default {

  data: function(){
    return {
      isFieldType: this.$store.state.loginMember.field.type,
      isAdminCheck:this.$store.state.loginMember.member.adminLevel,
      input1:"abc",
      myToggle:false,
      myToggle2:false,
      // HQ_NAME:this.$store.state.loginMember.field.headName,
      // CS_NAME:this.$store.state.loginMember.field.fieldName,
      // USER_POSITION: this.$store.state.loginMember.member.positionName,
      // USER_NAME:this.$store.state.loginMember.member.name,
      DOC_WAIT_COUNT: 5,
      DOC_APPLOVAL_COUNT: 0,
      DOC_FINISH_COUNT: 0,
      DOC_CANCEL_COUNT: 0,
      MENU_SEL_ID:"HOME",

      approvaldata:{
        pendingCount: 0,
        inProgressCount: 0,
        approveCount: 0,
        returnCount: 0
      }
    }
  },
  async created() {

    await this.$store.dispatch("fetchApprovalData");
  },

  computed: {
    // computedUnReadRiskDocumentListRequest() {
    //   return this.$store.getters.unReadRiskDocumentListRequest;
    // },
    // computedUnReadSafetyDocumentListRequest() {
    //   return this.$store.getters.unReadSafetyDocumentListRequest;
    // },
    computedPendingCount() {
      return this.$store.getters.pendingApprovalData;
    },
    computedInProgressCount() {
      return this.$store.getters.inProgressApprovalData;
    },
    computedApproveCount() {
      return this.$store.getters.approveApprovalData;
    },
    computedReturnCount() {
      return this.$store.getters.returnApprovalData;
    },
    RTMemberName() {
      const memberName = this.$store.getters.getMemberData.member.name;
      if (memberName && memberName.length >= 4) {
        // return memberName.slice(0, 3) + '...';
        return memberName;
      } else {
        return memberName;
      }
    },
    RTPositionName() {
      return this.$store.getters.getMemberData.member.positionName
    },
    RTFieldName(){
      return this.$store.getters.getMemberData.field.fieldName
    },
    RTHeadName(){
      return this.$store.getters.getMemberData.field.headName
    }
  },


  methods:{
    dropEvent(data) {

      if(this.$store.state.dropLayout == data) {
        this.$store.state.dropLayout = null
      }else {
        this.$store.state.dropLayout = data
      }
      if(data == 2) {
        this.$store.state.menuActive = '스케쥴러'
        this.$store.state.menuProgress = 2
        this.$router.push({name:'plan_list'})
      }else if(data == 1 && this.$store.state.loginMember.field.type == 'FIELD'){
        this.$store.state.menuProgress = 1
        this.$store.state.menuActive = '대시보드'
      }
      const scrollContainer = document.querySelector('.nano-container');
      this.$nextTick(() => {
          if (scrollContainer) {
              scrollContainer.scrollTo(0, scrollContainer.scrollHeight);
          }
      });
    },
    resetbranch() {
      const Fboard = document.querySelector('.drops')
      Fboard.classList.remove('bgcF')
    },
    layoutDownEvent() {
      const menu = document.querySelectorAll('.document-list-menu');
      const main = document.querySelector('#document-list-box')
      const nano = document.querySelector('.nano-container')

      if (main.classList.contains('Down')) {
        main.classList.remove('Down');
        nano.style.height = 'calc(100vh - 500px)';
      } else {
        main.classList.add('Down');
        nano.style.height = 'calc(100vh - 390px)';
      }

      menu.forEach(item => {
        if (item.classList.contains('Down')) {
          item.classList.remove('Down');
        } else {
          item.classList.add('Down');
        }
      });
    },
    MainEvent() {

      if(this.$store.state.loginMember.field.type == 'HEAD') {
        this.$router.push({path:'/ca_scene'})
      }else if(this.$store.state.loginMember.field.type == 'FIELD') {
        this.$router.push({path:'/ca_home"'})
      }
      this.$store.state.menuActive = '대시보드'
      this.$store.state.menuProgress = 1
      this.$store.state.dropLayout = 1

    },
    branchRoute(data, type) {
      sessionStorage.removeItem('routeState');
      this.$store.state.menuActive = null
      this.$store.state.menuProgress = null
      this.$store.state.menuActive = type // 게시판 타입

      // 안전소통의 방
      if(data == 'b_HEAD') { // 본사 공지 게시판

        this.$store.state.menuProgress = 1
        this.$router.push({name:'board_head'})
      }else if (data == 'b_FIELD') { // 현장 게시판
        this.$store.state.menuProgress = 2
        this.$store.state.targetFieldId = null
        if(this.$store.state.loginMember.field.type == 'HEAD') {
          this.$router.push({name:'board_head_branch'})
        }else if(this.$store.state.loginMember.field.type == 'FIELD') {
          this.$router.push({name:'board_branch'})
        }
      }else if(data == 'b_COMM') { // 소통 게시판
        this.$store.state.menuProgress = 3
        this.$router.push({name:'board_comm'})

        // 산업안전보건관리비
      }else if(data == '사용계획') {
        this.$store.state.menuProgress = 1
        this.$router.push({name:'management_list'})
      }else if(data =='사용내역서') {
        this.$store.state.menuProgress = 2
        this.$router.push({name:'management_operate'})

        // KOSHA-MS
      }else if(data =='E-manual') {
        this.$store.state.menuProgress = 1
        this.$router.push({name:'kosha_emanual'})
      }else if(data== '문서구축현황') {
        this.$store.state.menuProgress = 2
        this.$router.push({name:'ca_documentTotal'})
      }else if(data== '본사공유문서') {
        this.$store.state.menuProgress = 3
        if(this.$store.state.loginMember.field.type == 'FIELD') {
          this.$router.push({name:'kosha_head_com'})
        }else if(this.$store.state.loginMember.field.type == 'HEAD') {
          this.$router.push({name:'kosha_head_com_WAIT'})
        }
      }else if(data== '현장기초문서') {
        this.$store.state.menuProgress = 4
        if(this.$store.state.loginMember.field.type == 'FIELD') {
          this.$router.push({name:'kosha_branch_com'})
        }else if(this.$store.state.loginMember.field.type == 'HEAD') {
          this.$router.push({name:'kosha_branch_com_WAIT'})
        }
      }else if(data == 'KOSHA-MS 현황(현장)') {
        this.$store.state.menuProgress = 4
        this.$router.push({name : 'work_field_document_total'})
      }else if(data== 'KOSHA-MS 문서 리스트') {
        this.$store.state.menuProgress = 5
        this.$store.state.targetFolderId = null
        this.$store.state.pageChecking = null
        this.$router.push({name:'kosha_doclist'})

        // 안전보건경영방침
      }else if(data == '방침') {
        this.$store.state.menuProgress = 1
        this.$router.push({name:'policy_list'})
      }else if(data =='중점업무') {
        this.$store.state.menuProgress = 2
        this.$router.push({name:'policy_operate'})

        // 핵심업무
      }else if(data == '위험성평가') {
        this.$store.state.menuProgress = 1
        this.$router.push({name : 'work_risk_evalution'})
      }else if(data == '교육훈련') {
        this.$store.state.menuProgress = 2
        this.$router.push({name : 'work_edu_tranning'})
      }else if(data == '문서피드백'  && this.$store.state.loginMember.field.type == 'FIELD') {
        this.$store.state.menuProgress = 3
        this.$router.push({name : 'work_branch_activity'})
      }else if(data == '위험성평가 피드백' && this.$store.state.loginMember.field.type == 'HEAD') {
        this.$store.state.menuProgress = 3
        this.$router.push({name : 'work_head_activity'})
      }else if(data == '본사점검활동') {
        this.$store.state.menuProgress = 4
        this.$router.push({name : 'check_head_activity'})
      }else if(data =='모니터링') {
        this.$store.state.menuProgress = 1
        this.$router.push({name:'SceneMonitorDashboard'})
      }else if(data == '종합현황') {
        this.$store.state.menuProgress = 2
        this.$router.push({name:'HeadMonitorDashboard'})
      }
    },
    NoPage() {
      alert("준비중입니다")
      return
    },

    pending() {
    this.$store.commit('SET_APPROVAL_STATUS', 'PENDING');
    this.$router.push({ name: 'approval_list' });
  },
  progress() {
    this.$store.commit('SET_APPROVAL_STATUS', 'IN_PROGRESS');
    this.$router.push({ name: 'approval_list' });
  },
  approve() {
    this.$store.commit('SET_APPROVAL_STATUS', 'APPROVE');
    this.$router.push({ name: 'approval_list' });
  },
  returnE() {
    this.$store.commit('SET_APPROVAL_STATUS', 'RETURN');
    this.$router.push({ name: 'approval_list' });
  },
    getData() {
      if(this.myToggle ==false){
        this.myToggle = true;
      }
      else{
        this.myToggle = false;
      }
      //alert(this.input1);
    },

    async handleLogoutBtnClick() {
      try {
        await logoutRequest()

        document.cookie = 'access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        document.cookie = 'refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        this.$router.replace({
          name:'Login'
        })
      } catch (error) {
        const {message} = error
        alert(message)
      }
    },
    getMenuEventNavigationReset() {

      const submenuMainContainer = document.querySelectorAll(".submenu-main-container");

      const submenuFullContainer = document.querySelectorAll(".sub-menu");

      const submenuContainer = document.querySelectorAll(".submenu-container");

      const menuArrowIcon = document.querySelectorAll(".fa-angle-right");

      // const Fboard = document.querySelector('.drops')

      var submenuMainContainerLength = submenuMainContainer.length;

      for (var index = 0; index < submenuMainContainerLength; index++) {

        submenuMainContainer[index].classList.remove('active');
        submenuFullContainer[index].classList.remove('active');
        submenuContainer[index].classList.remove('active');
        menuArrowIcon[index].classList.remove('active');
        // Fboard.classList.remove('bgcF')
      }

    },
    getMenuEventNavigation(e) {

      // const Fboard = document.querySelector('.drops')

      const submenuMainContainer = document.querySelectorAll(".submenu-main-container");

      const submenuFullContainer = document.querySelectorAll(".sub-menu");

      const submenuContainer = document.querySelectorAll(".submenu-container");

      const menuArrowIcon = document.querySelectorAll(".fa-angle-right");


      const approvalDeleteArrow = [];

      menuArrowIcon.forEach(icon => {
          if (icon.id !== "") {
              approvalDeleteArrow.push(icon);
          }
      });
      var submenuMainContainerLength = submenuMainContainer.length;

      for (var index = 0; index < submenuMainContainerLength; index++) {

        if (index == e.target.id) {
          submenuMainContainer[e.target.id].classList.toggle('active');
          submenuFullContainer[e.target.id].classList.toggle('active');
          submenuContainer[e.target.id].classList.toggle('active');
          approvalDeleteArrow[e.target.id].classList.toggle('active');

        } else if (index != e.target.id) {
          submenuMainContainer[index].classList.remove('active');
          submenuFullContainer[index].classList.remove('active');
          submenuContainer[index].classList.remove('active');
          approvalDeleteArrow[index].classList.remove('active');
          // Fboard.classList.remove('bgcF')
        }

      }
    }

  },



};
</script>

<style lang="scss" scoped>
@import url('https://netdna.bootstrapcdn.com/font-awesome/4.0.3/css/font-awesome.min.css');

/*
*{
  padding: 0;
  margin: 0;
  border: none;
}*/

/* 기본 설정 */

body{
  font-size: 14px;
  font-family: 'NotoSansKR';
  box-sizing: border-box;
}


.sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 100%;
  background: #DFDFDF;
}

.top-nav-logo{
  position: relative;
  display: flex;
  align-items: center;

  width: 250px;
  min-height: 100px;
  background-color:white;
  justify-content: center;
}
#logo-img{
  padding: 0;
  border: 0;
  width:218px;
  height: 62px;
  cursor: pointer;
}


/* 로그인 박스 */
#side-login-topcolorbar{
  position: relative;
  display: block;

  width: 100%;
  height: 6px;
  color: #fff;
  font-size: 24px;
  /*background: transparent linear-gradient(90deg, #76B348 0%, #3F9DDA 100%) 0% 0% no-repeat padding-box;*/
  background: transparent linear-gradient(90deg, #78D25A 0%, #1EB4FF 100%) 0% 0% no-repeat padding-box;

}

#sidebar-login-info-box{
  position: relative;

  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  align-content: center;

  padding: 0;
  margin : 0;
  width: 100%;
  min-height:190px;
  background: white;
  z-index: 9;
}
.logined-wrapper{
  position: relative;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;

  width: 100%;
  height: 180px;
  background: white;

  padding-left: 10px;
  padding-right: 10px;
}

.loigined-info-title-wrap{
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: flex-start;
  width: 100%;


  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;

}

.loigined-info-title{
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;



  background: #0064B4 0% 0% no-repeat padding-box;
  border-radius: 2px;
  opacity: 1;

  font-family: 'NotoSansKR_Medium';
  color: white;
  font-size: 12px;

  width: 90px;
  height: 20px;
}


.logined-location-wrap{
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.logined-location-inner-wrap{
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  align-items: center;
  align-content: center;
}

.btn-option-box{
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;



  background: #0064B4 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  border: 0;

  font-family: 'NotoSansKR_Medium';
  color: white;
  font-size: 12px;

  width: 40px;
  height: 20px;
}



.logined-location-text-info{
  position: relative;
  /* display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: flex-start; */

  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;


  /* font-family: 'NotoSansKR_Bold'; */
  font: 14px 'NotoSansKR_Bold';
  color: #094E98;
  box-sizing: border-box;
  white-space: nowrap;
  align-items: center;
  justify-content: center;
  text-align: left;
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.logined-user-info-wrap{
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  width: 100%;

  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}


.logined-user-position{
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;

  font-family: 'NotoSansKR_Medium';
  color: #094E98;
  font-size: 16px;

  width: 50%;

}
.logined-user-name{
  position: relative;

  // display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;

  font-family: 'NotoSansKR_Bold';
  color: #094E98;
  font-size: 16px;

  width: 30%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}
.logined-user-other{
  position: relative;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;

  font-family: 'NotoSansKR_Medium';
  color: #094E98;
  font-size: 14px;

  width: 10%;
}

.logined-user-config{
  position: relative;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;

  font-family: 'NotoSansKR_Medium';
  color: #094E98;
  font-size: 14px;

  border: none;
  background-color: transparent;

  width: 10%;
}


.logined-button-wrap{
  position: relative;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;


  padding-left: 10px;
  padding-right: 10px;
}

.logined-button {
  position: relative;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center  ;
  width: 100%;
  height:30px;
  color: #094E98;

  border: 0;
  border-radius: 5px;
  opacity: 1;

  font-family: "NotoSansKR_Bold";
  background: #A4CAF4 0% 0% no-repeat padding-box;
  font-size: 14px;

  transition: 0.1s;
}
.logined-button:hover {
  color: #fff;
  background: #094E98 0% 0% no-repeat padding-box;
  cursor: pointer;
}



/* 문서결제 박스 */
#document-list-box{
  position: relative;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;

  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #707070;

  padding: 0;
  margin : 10px 0 10px 0;
  width: 100%;
  min-height: 152px;
  background: #EEEEEE;
  transition: 0.5s;

}
.nano-container::-webkit-scrollbar-track {
  background-color: #dfdfdf;
  border-radius: 10px;
  /* box-shadow: inset 0px 0px 5px white; */

}
.nano-container::-webkit-scrollbar-thumb {
  background-color: #2f3542;
  border-radius: 10px;
  background-clip: padding-box;
  border: 2px solid transparent;
  height: 1px;
}
.nano-container::-webkit-scrollbar {
  width:10px;

}

@media screen and (max-height: 550px) {
  #document-list-box {
    /* position: absolute; */
    /* position: absolute; */
    /* top: 300px; */
    /* bottom: 100px; */
    /* transform: translateY(-160px); */

    /* z-index: -9; */
  }
  #service-menu-title-box {
    /* transform: translateY(-160px); */

  }
  .service-menu-box {
    /* transform: translateY(-160px);
    transition: 0.5s; */
  }
  .nano-container {
    height: calc(100vh - 350px) !important;
  }

}

#document-title-box{
  position: relative;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;

  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: #707070;

  padding: 0;
  margin : 0;
  width: 100%;
  height:40px;
  background: white;
}

#document-title-name{
  position: relative;
  border: 0;
  padding: 0;
  margin:  auto 0  auto 10px;

  font-family:'NotoSansKR-Bold';
  font-weight:bold;
  font-size: 15px;
  color: #5F5F5F ;

  background: #FFFFFF;
  white-space: nowrap;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.document-list-wrap{
  position: relative;
  height: 110px;
  display: flex;

  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;

  width: 100%;

cursor: pointer;

  padding-left: 10px;
  padding-right: 10px;
}

.document-list{

  list-style: none;
  margin:0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 10px;
  padding-bottom: 10px;

  position: relative;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  font-family:'NotoSansKR_Regular';
  font-weight:normal;
  font-size: 12px;
  color: #6D6D6D ;


  white-space: nowrap;
  text-align: left;
  align-items: left;
  justify-content: left;

  width: 100%;

}



.document-list-menu {
  position: relative;
  display: flex;

  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 25px;

  font-family:'NotoSansKR_Regular';
  font-weight:normal;
  font-size: 12px;
  color: #6D6D6D ;

  text-decoration-line: none;
  align-items: center;

  .introCircle {
    margin-top: 6px;
  }
}

.document-list-menu:hover {

  font-family:'NotoSansKR_Bold';
  color: #4d4d4d ;
}



/* 서비스 메뉴  */
#service-menu-title-box{
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;

  padding: 0;
  margin : 0px 0 0 0;
  width: 100%;
  min-height:40px;
  background: white;
  transition: 0.5s;
}

#service-menu-title-name{
  position: relative;
  border: 0;
  padding: 0;
  margin:  auto 0  auto 10px;

  font-family:'NotoSansKR-Bold';
  font-weight:bold;
  font-size: 15px;
  color: #5F5F5F ;

  background: #FFFFFF;
  white-space: nowrap;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.service-menu-box{
  position: relative;
  display: block;
  padding-top: 10px;
  margin: 0;

  width: 100%;
  height:auto;
  /* height: 2000px; */
  background: transparent;

  text-align: center;
  transition: 0.5s;
}



.service-main-menu{
  position: relative;
  display: block;
  padding: 0;
  margin: 0;

  width: 100%;
  height:40px;
  background: red;
  text-align: right;
}




.main-button {
  position: relative;
  display: inline-block;


  border: 0;
  width: 90%;
  height: 40px;

  font-family:'NotoSansKR-Bold';
  font-weight:bold;
  font-size: 15px;
  color: #5F5F5F;

  background: white 0% 0% no-repeat padding-box;
  border-radius : 20px 0px 0px 20px;
  opacity: 1;
  cursor: pointer;

  transition: 0.25s;
}

.main-button:active {
  color: #5F5F5F;
  background: white 0% 0% no-repeat padding-box;
  cursor: pointer;
}
.main-button {
  color: #5F5F5F;
  background: white 0% 0% no-repeat padding-box;
  cursor: pointer;
}


.nav-item a{
  position: relative;
  display: inline-block;
  width: 220px;
  font-family:'NotoSansKR-Medium';
  font-weight:normal;
  font-size: 15px;
  text-align: left;
  color: #5F5F5F;
  border-radius : 20px 0px 0px 20px;
  opacity: 1;
}


.active-route {
  position: relative;
  display: inline-block;
  width: 100px;
  background-color: white;
  font-weight: bold;
}

.active-route2 {
  position: relative;
  display: inline-block;
  width: 100%;
  background-color: white;
  font-weight: bold;
}



/*공용객체 */
.figure-circle {
  position: relative;
  margin: auto 0 auto 10px;
  width : 15px;
  height : 15px;
  border-radius: 50%;
  background-color: #3F9DDA;
}


/* 서비스 메뉴 */

a {
  text-decoration: none;
  font-family: 'NotoSansKR_Medium';
}




.asideBar {
  width: 100%;
  height: 100%;
  position: absolute;
}


.nano-container {
  text-align: left;
  color: #5F5F5F;
  text-decoration: none;
  display: block;
  padding: 20px 25px 20px 25px;
  outline: 0;
  list-style-type: none;
  margin-bottom: 2%;
  height: calc(100vh - 500px);
  overflow-y: auto;
  overflow-x: hidden;
  margin: -2px 0 0;
  padding: 0;
  padding-bottom: 30px;
}



.nano-container span, svg, a {
  color: #5F5F5F;
}


/* 대시보드 */
.dashboard-container {
  padding: 3% 0 3% 4%;
  margin: 0 0 0 2%;
  font-size: 16px;
}

.plan-container {
  padding: 3% 0 3% 4%;
  margin: 2% 0 0 2%;
  font-size: 16px;
  cursor: pointer;


}
.plan-container.active {
  background-color: #fdfdff;
  border-radius: 80px 0 0 80px;
  font-weight: bold;
}
.plan-text {
  cursor: pointer;
}
.plan-container:hover {
  background-color: #fdfdff;
  border-radius: 80px 0 0 80px;
  font-weight: bold;
}
.plan-container:hover .img-active {
  display: block;

}
.plan-container:hover .img-inactive {
  display: none;
}
.dashboard-container:hover {
  background-color: #fdfdff;
  border-radius: 80px 0 0 80px;
  font-weight: bold;
}
.router-link-active .dashboard-container {
  background-color: #fdfdff;
  border-radius: 80px 0 0 80px;
  font-weight: bold;
}

.router-link-active .plan-container {
  background-color: #fdfdff;
  border-radius: 80px 0 0 80px;
  font-weight: bold;
}
.dashboard-container span {
  padding-left: 4%;
}

.plan-container span {
  padding-left: 4%;
}



.sub-menu {
  padding: 3% 0 0 4%;
  margin: 0 0 0 2%;

}
.sub-menu:hover .img-active {
  display: block;
}
.sub-menu:hover .img-inactive {
  display: none;
}
.sub-menu.active .img-active {
  display: block;
}
.sub-menu.active .img-inactive {
  display: none;
}

.submenu-main-container {
  // padding: 5% 0 5% 0;
  display: flex;
  // height: 41px;
  position: relative;

}
.submenu-main-container span {
  padding-left: 4%;
  font-size: 16px;
  display: block;
  width: 100%;
  display: flex;
  // padding-top: 5px;
  align-items: center;
  // mar

  .introCircle {
    margin-top: 3px;
  }
}
.submenu-container {
  margin: -2px 0 0 12%;
  padding: 0;
  list-style-type: none;
  text-align: left;
  font-size: 14px;

  display: none;
  transform-origin: top center;
  animation: fade-in .5s;
}

.submenu-container.active {
  display: block;
  padding-top: 3%;
  background-color: #DFDFDF;
}

/* fade-in 애니메이션 */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}



/* 사이드 메뉴 아이콘 */
.fa-angle-left {
  padding-top: 2%;
}
.fa-angle-right {
  padding: 2% 4% 0 4%;
}
.fa-angle-right.pull-right.active {
  transform: rotate( 90deg );
  transition: all ease .3s;
}



/* 하위 메뉴 */
.submenu-drop {
  height: 35px;
  margin-top: 1%;
  cursor: pointer;
  padding: 3.5% 0 0 12%;
  position: relative;
  display: flex;

  .introCircle {
    margin-top: 8px;
  }
}
.submenu-drop:hover {
  background-color: #fdfdff;
  border-radius: 80px 0 0 80px;
  font-weight: bold;
}

.router-link-active .submenu-drop {
  background-color: #fdfdff;
  border-radius: 80px 0 0 80px;
  font-weight: bold;
}
.router-link-active .img-active {
  display: block;
}
.router-link-active .img-inactive {
  display: none;
}
.img-active {
  display: none;
  pointer-events: none;
  position: relative;
  z-index: 9999;
}
.img-inactive {
  display: block;
  pointer-events: none;
  position: relative;
  z-index: 9999;
}


[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-i_board {
  pointer-events: none;
}
.icon-i_board:before {
  content: "\e900";
  color: #5f5f5f;
}
.icon-i_dash {
  pointer-events: none;

}
.icon-i_dash:before {
  content: "\e901";
  color: #5f5f5f;
}
.icon-i_gear:before {
  content: "\e902";
  color: #9e9e9e;
}
.icon-i_kosha {
pointer-events: none;
}
.icon-i_kosha:before {
  content: "\e903";
  color: #5f5f5f;
}
.icon-i_management {
pointer-events: none;
}
.icon-i_management:before {
  content: "\e904";
  color: #5f5f5f;
}
.icon-i_plan {
  pointer-events: none;
  cursor: default;

}
.icon-i_plan:before {
  content: "\e905";
  color: #5f5f5f;
}
.icon-i_policy {
pointer-events: none;
}
.icon-i_policy:before {
  content: "\e906";
  color: #5f5f5f;
}
.icon-i_refresh:before {
  content: "\e907";
  color: #5f5f5f;
}
.icon-i_work {
  pointer-events: none;
}
.icon-i_work:before {
  content: "\e908";
  color: #5f5f5f;

}

.approval-box {
  cursor: pointer;
  justify-content: space-between !important;
  align-items: center;

  .left {
    display: flex;
  }
  i {
    padding: 0px;
    margin-right: 10px;
  }
}
#document-list-box {
  transition: 0.5s !important;
}

.fa-angle-right.pull-right {
  transition: all ease .3s;
}
#document-list-box.Down {
  border-bottom:0px;
  min-height: 0px;
  .document-list-wrap {
    height: 0px;
  }
  #document-title-box {
    border: 0px;
  }
  .fa-angle-right.pull-right {
    transform: rotate( 90deg );
    transition: all ease .3s;
  }
  .approval-box {

  }
  .document-list {
    position: absolute;
    z-index: -9;
  }
}

.document-list-menu.Down {
  opacity: 0;
}

.active {
  .bgcF {
    background-color: #fff;
    border-radius: 80px 0 0 80px;
    a{
      font-weight: bold;

    }
  }
}

.introCircle {
  font-size: 10px;
  font-family: 'NotoSansKR_Regular';
  display: block;
  // margin-top: 6px;
  margin-left: 6px;
  width: 7px;
  height: 7px;
  color: white;
  background-color: #E47663;
  border-radius: 100%;
  // position: absolute;
  // right:102px;
  // top:25%;
  transform: translateY(-50%);
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // margin-left: 10px;
}
</style>

